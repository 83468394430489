html {
    overflow-x: hidden;
}
.page {
    &.-header {
        $header-height: 95vh;
        // min-height: $header-height;
        position: relative;

        .bg {
            // padding-bottom: 100px;
            width: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                min-height: 300px;
                object-position: center bottom;
                max-height: $header-height;
            }
        }

        .absolute-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        .head-content {
            height: calc(100% - 62px);

            &.-contacts-us {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .columns-2 {
            max-width: 1110px;
            margin: 0 auto;
            width: calc(100% - (#{$page-padding} * 2));
            padding: 0 $page-padding;
            height: 100%;

            display: block;

            @include breakpoint(md) {
                width: calc(100% - (#{$md-page-padding} * 2));
                padding: 0 $md-page-padding;
            }

            @include breakpoint(lg) {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                height: 60%;
            }

            @include breakpoint(xl) {
                width: calc(100% - (#{$xl-page-padding} * 2));
                padding: 0 $xl-page-padding;
            }

            .col-left,
            .col-right {
                width: 100%;
                // margin-top: 50px;

                @include breakpoint(lg) {
                    width: 50%;
                }
            }

            .col-left {
                .title {
                    font-size: 32px;
                    line-height: 46px;
                    max-width: 100%;
                    margin-top: 30px;
                    margin-bottom: 30px;

                    @include breakpoint(md) {
                        font-size: 50px;
                        line-height: 64px;
                    }

                    @include breakpoint(lg) {
                        max-width: 478px;
                        margin-bottom: 0;
                        margin-top: 0;
                    }

                    @include breakpoint(xl) {
                        font-size: 70px;
                        line-height: 84px;
                    }
                }
            }

            .col-right {
                padding-top: 15px;
                .description {
                    font-size: 16px;
                    line-height: 24px;
                }

                display: none;

                @include breakpoint(md) {
                    display: block;
                }

                .tabs {
                    ul {
                        padding-bottom: 25px;

                        @include breakpoint(lg) {
                            padding-bottom: 30px;
                        }

                        li {
                            display: inline-block;
                            margin-right: 31px;
                            text-transform: uppercase;
                            font-weight: bold;
                            cursor: pointer;

                            &:last-child {
                                margin-right: 0;
                            }

                            &.active {
                                color: $blue;
                                position: relative;
                            }

                            &.active::after {
                                content: "";
                                position: absolute;
                                bottom: -7px;
                                left: 0;
                                width: 100%;

                                border-bottom: 3px solid $blue;
                            }
                        }
                    }
                }
            }
        }

        .columns-1 {
            padding: 0 $page-padding;
            padding-bottom: 120px;
            padding-right: 40px;
            width: 100%;
            z-index: -1;

            max-width: 1110px;
            margin: 0 auto;
            // padding-top: 20vh;
            @include breakpoint(md) {
                padding: 0 $md-page-padding;
                padding-bottom: 300px;
                // padding-top: 80px;
            }

            @include breakpoint(xl) {
                padding: 0 $xl-page-padding;
                padding-bottom: 300px;
                // padding-top: 164px;
            }

            .title {
                font-size: 33px;
                line-height: 49px;
                text-align: center;

                @include breakpoint(md) {
                    font-size: 50px;
                    line-height: 64px;
                }

                @include breakpoint(xl) {
                    line-height: 84px;
                    font-size: 70px;
                }
            }

            .title-right {
                font-size: 18px;
                line-height: 25px;
                text-align: right;
                width: 100%;
                float: right;

                &.-fs-small {
                    padding-top: 60px;
                    font-size: 20px;
                }

                @include breakpoint(sm) {
                    width: 400px;
                }

                @include breakpoint(md) {
                    font-size: 30px;
                    line-height: 40px;
                    width: 520px;

                    &.-fs-small {
                        font-size: 25px;
                    }
                }

                @include breakpoint(xl) {
                    font-size: 50px;
                    line-height: 60px;
                    width: 800px;

                    &.-fs-small {
                        font-size: 40px;
                    }
                }
            }
        }
    }

    &.-footer {
        max-width: 1440px;
        margin: 0 auto;
        .footer-bottom {
            height: 70px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            padding: 0 $page-padding;
            padding-bottom: 20px;

            @include breakpoint(sm) {
                height: 106px;
                padding-bottom: 0px;
            }

            @include breakpoint(md) {
                padding: 0 $md-page-padding;
            }

            @include breakpoint(xl) {
                padding: 0 $xl-page-padding;
            }

            & > a {
                transition: color 0.2s ease-in-out;

                @media screen and (max-width: 349px) {
                    margin: 0 auto;
                    padding: 8px 0;
                }
            }

            & > a:hover {
                color: $text-hover;
            }

            p {
                font-size: 16px;
                line-height: 24px;

                a {
                    display: block;
                    margin-left: 10px;
                }

                &:last-child {
                    display: flex;
                    align-items: baseline;
                }

                @media screen and (max-width: 350px) {
                    width: 100%;
                    text-align: center;
                }
            }

            p:last-child {
                width: 100%;
                justify-content: center;
            }

            @include breakpoint(sm) {
                p:last-child {
                    width: auto;
                }
            }
        }
    }

    .footer-top {
        position: relative;

        margin-bottom: 30px;

        @include breakpoint(sm) {
            margin-bottom: 0;
        }

        .content {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 0 $page-padding;
            width: calc(100% - (#{$page-padding} * 2));
            height: 140px;
            padding-top: 30px;
            padding-bottom: 30px;

            max-width: 1110px;
            margin: 0 auto;

            @include breakpoint(sm) {
                height: 408px;
                padding-top: 0;
                padding-bottom: 0;
            }

            @include breakpoint(md) {
                padding: 0 $md-page-padding;
                width: calc(100% - (#{$md-page-padding} * 2));
            }

            @include breakpoint(xl) {
                height: 408px;
                padding: 0 $xl-page-padding;
                width: calc(100% - (#{$xl-page-padding} * 2));
            }

            @include breakpoint(xxl) {
                height: calc(408px * 1.35);
            }

            .col-left {
                width: 100%;

                @include breakpoint(sm) {
                    width: 70%;
                }
            }

            .col-right {
                width: 100%;

                @include breakpoint(sm) {
                    width: 30%;
                }
            }

            .col-left {
                .question {
                    font-size: 25px;
                    line-height: 35px;
                    text-align: center;

                    @include breakpoint(sm) {
                        text-align: left;
                    }
                }

                .title {
                    font-size: 32px;
                    line-height: 46px;
                    text-align: center;

                    @include breakpoint(sm) {
                        text-align: left;
                    }
                }

                @include breakpoint(md) {
                    .question {
                        font-size: 30px;
                        line-height: 40px;
                    }

                    .title {
                        font-size: 50px;
                        line-height: 64px;
                    }
                }

                @include breakpoint(xl) {
                    .question {
                        font-size: 50px;
                        line-height: 60px;
                    }

                    .title {
                        font-size: 70px;
                        line-height: 84px;
                    }
                }
            }

            .col-right {
                display: flex;
                justify-content: center;
                align-items: center;

                @include breakpoint(sm) {
                    justify-content: flex-end;
                }

                a {
                    font-size: 25px;
                    line-height: 35px;
                    text-decoration: none;
                    color: $white;

                    @include breakpoint(md) {
                        font-size: 30px;
                        line-height: 40px;
                    }

                    @include breakpoint(xl) {
                        font-size: 50px;
                        line-height: 60px;
                    }
                }

                i {
                    margin-left: 12px;
                }
            }
        }

        .-bg {
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;

            background-image: url("./../../assets/bg/normal-page-footer-bg.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            height: 100%;
            width: 100%;
        }
    }

    &.-page-content {
        max-width: 1100px;
        margin: 0 auto;
        width: calc(100% - (#{$page-padding} * 2));
        padding: 24px $page-padding 35px $page-padding;

        @include breakpoint(md) {
            width: calc(100% - (#{$md-page-padding} * 2));
            padding: 24px $md-page-padding 35px $md-page-padding;
        }

        @include breakpoint(xl) {
            width: calc(100% - (#{$xl-page-padding} * 2));
            padding: 24px $xl-page-padding 35px $xl-page-padding;
        }
    }
}

.privacy-policy {
    h4 {
        font-weight: 600;
        font-size: 18px;
        margin-top: 30px;
    }
}
