.mobile-menu {
    position: fixed;
    height: 100vh;
    overflow-y: auto;
    width: 100%;
    top: 0;
    right: -400px;
    z-index: 999;
    transition: all 0.5s ease-in-out;
    background: linear-gradient(
        rgba(2, 0, 36, 0.98) 0%,
        rgba(0, 0, 0, 0.98) 100%
    );

    @media screen and (min-width: 400px) {
        width: 300px;
    }

    @include breakpoint(lg) {
        display: none;
    }

    &.active {
        right: 0;
    }

    .close-btn {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .menu-inner {
        padding: 40px 0;
        width: 100%;
        box-sizing: border-box;

        .title {
            width: 100%;
            font-size: 50px;
            text-align: center;
            text-align: center;
            margin-bottom: 40px;
        }

        .menu-buttons {
            padding: 0 50px;

            .button-container {
                padding: 8px 0;

                a {
                    transition: color 0.2s ease-in-out;
                }

                a:hover {
                    color: $text-hover;
                }

                a.active {
                    color: $white;
                    padding-left: 15px;
                    position: relative;

                    &::before {
                        content: "";
                        width: 10px;
                        height: 2px;
                        background: $white;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        transition: background 0.2s ease-in-out;
                    }

                    &:hover {
                        color: $text-hover;

                        &::before {
                            background: $text-hover;
                        }
                    }
                }
            }
        }

        .social {
            display: flex;
            padding: 0 50px;
            margin-top: 40px;

            a {
                margin-right: 15px;

                .icon.-facebook {
                    width: calc(8px * 1.3);
                    height: calc(16px * 1.3);
                }
                .icon.-linkedin {
                    width: calc(16px * 1.3);
                    height: calc(16px * 1.3);
                }
            }
        }

        .contacts {
            margin-top: 40px;
            padding: 0 50px;
            .phone,
            .email {
                display: flex;
                padding-bottom: 20px;
                align-items: center;

                .icon {
                    margin-right: 10px;
                }
            }
        }
    }
}
