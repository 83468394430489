#product-view {
    .columns-2 {
        .col-right {
            display: none;

            @include breakpoint(lg) {
                display: block;
            }
        }
    }

    .mobile-description {
        display: block;
        padding-right: 20px;

        @include breakpoint(lg) {
            display: none;
        }
    }
}

.about-prod {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;

    @include breakpoint(lg) {
        margin-top: 124px;
    }

    .left {
        width: 100%;
        margin-right: 0px;
        position: relative;
        margin-bottom: 40px;

        @include breakpoint(sm) {
            width: 50%;
            margin-bottom: 0;
        }

        @include breakpoint(xl) {
            width: calc(50% - 65px);
            margin-right: 65px;
        }

        .bg {
            background: linear-gradient(
                266.24deg,
                rgba(20, 156, 255, 0) 0%,
                rgba(41, 22, 255, 0.42) 96.11%
            );
            border-radius: 20px;

            position: absolute;

            right: 0;
            top: -24px;
            z-index: -1;

            height: 60px;
            width: calc(100% - 27px);

            @include breakpoint(xs) {
                width: calc(100% - 37px);
                top: -24px;
            }

            @include breakpoint(md) {
                width: calc(100% - 57px);
            }
        }

        img {
            position: relative;
            border-radius: 20px;
            width: 100%;
            max-height: 507px;
            max-width: 507px;
            object-fit: cover;
            object-position: center;
        }

        .img-list-container {
            margin-top: 10px;
            width: calc(100% + 10px);
            margin-left: -5px;
            margin-right: -5px;
            position: relative;

            #singleProdImgPrev {
                background-image: url("./../../assets/icons/arrow-left.svg");
                background-repeat: no-repeat;
                background-size: contain;
                width: 40px;
                height: 40px;
                position: absolute;
                top: 50%;
                left: -15px;
                z-index: 99;
                transform: translateY(-40%);
                cursor: pointer;
            }

            #singleProdImgNext {
                background-image: url("./../../assets/icons/arrow-right.svg");
                background-repeat: no-repeat;
                background-size: contain;
                width: 40px;
                height: 40px;
                position: absolute;
                top: 50%;
                right: -15px;
                z-index: 99;
                transform: translateY(-40%);
                cursor: pointer;
            }
        }

        #mainImage {
            cursor: zoom-in;
        }

        #imgList {
            .slick-slide {
                padding: 0 5px;

                img {
                    height: 100px;
                    width: 100px;
                    object-fit: cover;
                    object-position: center;
                    cursor: pointer;
                }
            }

            .slick-track {
                margin: 0 auto 0 0;
            }
        }
    }

    .right {
        width: 100%;

        @include breakpoint(sm) {
            width: 50%;
        }

        .info {
            padding-left: 0px;
            margin-bottom: 20px;

            @include breakpoint(sm) {
                padding-left: 32px;
                margin-bottom: 20px;
            }

            @include breakpoint(xl) {
                padding-left: 65px;
                margin-bottom: 40px;
            }

            .title {
                font-weight: bold;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.09em;
                text-transform: uppercase;
            }

            .sub-title {
                font-size: 20px;
                line-height: 28px;

                @include breakpoint(md) {
                    font-size: 30px;
                    line-height: 38px;
                }

                @include breakpoint(xl) {
                    font-size: 40px;
                    line-height: 48px;
                }
            }
        }
    }
}

.other-prod-info {
    margin-top: 0;
    margin-bottom: 0;
    position: relative;
    min-height: 100px;

    @include breakpoint(md) {
        margin-top: 128px;
        margin-bottom: 57px;
        min-height: 776px;
    }

    .bg {
        background-image: url("./../../assets/bg/inside-product-line-bg.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        height: 282px;
        width: 100%;

        position: absolute;
        top: 50%;
        left: 0;
        z-index: -1;
        transform: translateY(-50%);

        @include breakpoint(sm) {
            height: 382px;
        }

        @include breakpoint(md) {
            height: 582px;
            top: 0;
            transform: translateY(0);
        }

        @include breakpoint(xxl) {
            height: 776px;
        }
    }

    .content {
        max-width: 1110px;
        width: calc(100% - (#{$page-padding} * 2));
        margin: 0 auto;
        padding: 30px $page-padding 27px $page-padding;

        @include breakpoint(sm) {
            padding: calc(169px / 2) $page-padding 27px $page-padding;
        }

        @include breakpoint(md) {
            padding: calc(169px / 1.8) $md-page-padding 37px $md-page-padding;
            width: calc(100% - (#{$md-page-padding} * 2));
        }

        @include breakpoint(xl) {
            width: calc(100% - (#{$xl-page-padding} * 2));
        }

        @include breakpoint(xxl) {
            padding: 169px $xl-page-padding 47px $xl-page-padding;
        }

        .title {
            font-size: 32px;
            line-height: 46px;

            @include breakpoint(md) {
                font-size: 50px;
                line-height: 64px;
            }

            @include breakpoint(xl) {
                font-size: 70px;
                line-height: 84px;
            }
        }
    }

    .row-info-1,
    .row-info-2 {
        max-width: 1110px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        padding: 0 $page-padding;

        @include breakpoint(md) {
            padding: 0 $md-page-padding;
        }

        @include breakpoint(xl) {
            padding: 0 $xl-page-padding;
        }

        .info {
            .title {
                font-weight: bold;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.09em;
                text-transform: uppercase;
                margin-bottom: 8px;
            }

            .des {
                font-size: 16px;
                line-height: 24px;
                padding-right: 10px;
            }
        }
    }

    .row-info-1 {
        margin-bottom: 0px;

        @include breakpoint(md) {
            margin-bottom: 41px;
        }

        .info {
            width: 100%;
            margin-bottom: 20px;

            @include breakpoint(md) {
                width: 20%;
                margin-bottom: 0;
            }
        }
    }

    .row-info-2 {
        padding-bottom: calc(219px / 2);

        @include breakpoint(md) {
            padding-bottom: calc(219px / 1.8);
        }

        @include breakpoint(xxl) {
            padding-bottom: 219px;
        }

        .info {
            width: 100%;
        }

        .info:last-child {
            width: 100%;

        }

        @include breakpoint(md) {
            .info {
                width: 40%;
            }
            .info:last-child {
                width: 60%;
            }
        }

        .info:last-child {
            margin-top: 20px;
        }

        @include breakpoint(xl) {
            .info:last-child {
                // width: 40%;
                width: 60%;
            }
        }
    }

    &.table .content {
        padding-top: 0px;
        
        @include breakpoint(sm) {
            padding-top: 80px;
        }
    }

    .overflow-hidden {
        overflow-x: hidden;
        max-width: 1110px;
        width: calc(100% - (#{$page-padding} * 2));
        margin: 0 auto;
        padding: 0 $page-padding;

        @include breakpoint(md) {
            width: calc(100% - (#{$md-page-padding} * 2));
            padding: 0 $md-page-padding;
        }

        @include breakpoint(xl) {
            width: calc(100% - (#{$xl-page-padding} * 2));
            padding: 0 $xl-page-padding;
        }
    }

    .specification-table {
        width: 100%;
        overflow-x: auto;

        table {
            width: 100%;
            min-width: 500px;

            @include breakpoint(sm) {
                min-width: 700px;
            }

            th,
            td {
                border: 1px solid $white;
                padding: 5px;
                font-size: 14px;
                &:nth-child(1) {
                    text-align: left;
                }

                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5) {
                    text-align: center;
                }

                @include breakpoint(sm) {
                    border: 2px solid $white;
                    padding: 10px;
                    font-size: 16px;
                }
            }
        }
    }

    .specification-list {
        max-width: 1110px;
        width: calc(100% - (#{$page-padding} * 2));
        margin: 0 auto;
        padding: 0 $page-padding;
        padding-bottom: 50px;

        @include breakpoint(md) {
            width: calc(100% - (#{$md-page-padding} * 2));
            padding: 0 $md-page-padding;
        }

        @include breakpoint(xl) {
            width: calc(100% - (#{$xl-page-padding} * 2));
            padding: 0 $xl-page-padding;
        }

        .row {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            .col-50 {
                box-sizing: border-box;
                width: 100%;
                padding-bottom: 30px;
                &:first-child {
                    padding-right: 20px;
                }

                @include breakpoint(sm) {
                    width: 50%;
                }

                @include breakpoint(md) {
                    &:first-child {
                        padding-right: 50px;
                    }
                }

                .title {
                    font-size: 25px;
                    margin-bottom: 20px;
                }

                .info {
                    padding-bottom: 8px;
                }
            }
        }
    }
}

.prod-variations {
    .title {
        font-size: 70px;
        line-height: 84px;
        text-align: center;
    }

    .product-list {
        padding: 0;
    }
}

.image-preview {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;

    background: rgb(6, 2, 51, 0.7);

    display: none;
    opacity: 0;
    justify-content: center;
    align-items: center;

    &.-active {
        display: flex;
    }

    img {
        max-width: 80vw;
        max-height: 90vh;
    }

    .icon {
        cursor: pointer;

        &.-arrow-right {
            margin-left: -20px;
            z-index: 2;

            @include breakpoint(lg) {
                margin-left: -37px;
            }
        }

        &.-arrow-left {
            margin-right: -20px;
            z-index: 2;

            @include breakpoint(lg) {
                margin-right: -37px;
            }
        }
    }

    #closeGallery {
        position: fixed;
        z-index: 3;
        top: 20px;
        right: 20px;
        height: 30px;
        width: 30px;
    }
}


.row-with-img {
    padding: 0 20px 27px 20px;
    width: calc(100% - (20px * 2));
    max-width: 1110px;
    margin: 0 auto;
    display: flex;

    @include breakpoint(md) {
        padding: calc(169px / 1.8) 80px 37px 80px;
        width: calc(100% - (80px * 2));
    }

    @include breakpoint(xl) {
        padding: 0 165px;
        width: calc(100% - (165px * 2));
    }

    @media screen and (max-width: 1000px) {
        flex-wrap: wrap;
    }

    .row-info {
        .row-info-1,
        .row-info-2 {
            padding: 0;
        }
    }

    .row-certificate {
        padding-left: 20px;

        @media screen and (max-width: 1000px) {
            width: 100%;
            margin-top: 50px;
            padding-left: 0;
        }

        img {
            width: 100%;
            max-width: 400px;
        }
    }

}
