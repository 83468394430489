html:lang(lt) {
  .section-content .products .product-list .product-card .title {
    font-size: 16px;
    line-height: 21px;

    @include breakpoint(lg) {
      font-size: 25px;
      line-height: 30px;
    }

    @include breakpoint(xxl) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  .page .footer-top .content .col-left .title,
  .section-content.footer-content .footer.-top .content .left .second {
    @include breakpoint(xl) {
      font-size: 55px;
      line-height: 69px;
    }
  }

  .page .footer-top .content .col-left .question,
  .section-content.footer-content .footer.-top .content .left .first {
    @include breakpoint(xl) {
      font-size: 35px;
      line-height: 45px;
    }
  }

  .page.-header .columns-2 .col-right .tabs ul li:last-child {
    margin-top: 14px;
  }

  .product-row.right-align .title, .product-row.left-align .title {
    @include breakpoint(md) {
      font-size: 30px;
      line-height: 40px;
    }
    @include breakpoint(xl) {
      font-size: 35px;
      line-height: 47px;
    }
  }

  .page.-header .columns-2 .col-left .title {
    @include breakpoint(md) {
      font-size: 35px;
      line-height: 45px;
    }
    @include breakpoint(xl) {
      font-size: 45px;
      line-height: 50px;
    }
  }
}