.icon {
    &.-facebook {
        background-image: url("./../../../assets/icons/facebook.svg");
        background-repeat: no-repeat;
        background-size: contain;

        width: 8px;
        height: 16px;

        transition: all 0.2s ease-in-out;

        &:hover {
            filter: $svg-hover-filter;
        }
    }

    &.-linkedin {
        background-image: url("./../../../assets/icons/linkedin.svg");
        background-repeat: no-repeat;
        background-size: contain;

        width: 16px;
        height: 16px;

        transition: all 0.2s ease-in-out;

        &:hover {
            filter: $svg-hover-filter;
        }
    }

    &.-globe {
        background-image: url("./../../../assets/icons/global.svg");
        background-repeat: no-repeat;
        background-size: contain;

        width: 16px;
        height: 16px;

        transition: all 0.2s ease-in-out;

        &:hover {
            filter: $svg-hover-filter;
        }
    }

    &.-dropdown-arrow-down {
        background-image: url("./../../../assets/icons/dropdown_arrow_down.svg");
        background-repeat: no-repeat;
        background-size: contain;

        width: 9px;
        height: 5px;
    }

    &.-envelope {
        background-image: url("./../../../assets/icons/envelope.svg");
        background-repeat: no-repeat;
        background-size: contain;

        width: 18px;
        height: 14px;
    }

    &.-circle-arrow {
        background-image: url("./../../../assets/icons/arrow.svg");
        background-repeat: no-repeat;
        background-size: contain;

        width: 15px;
        height: 15px;
    }

    &.-blue-arrow {
        background-image: url("./../../../assets/icons/blue_arrow.svg");
        background-repeat: no-repeat;
        background-size: contain;

        width: 40px;
        height: 12px;
    }

    &.-arrow-left {
        background-image: url("./../../../assets/icons/arrow-left.svg");
        background-repeat: no-repeat;
        background-size: contain;

        width: 43px;
        height: 43px;

        @include breakpoint(lg) {
            width: 73px;
            height: 73px;
        }
    }

    &.-arrow-right {
        background-image: url("./../../../assets/icons/arrow-right.svg");
        background-repeat: no-repeat;
        background-size: contain;

        width: 43px;
        height: 43px;

        @include breakpoint(lg) {
            width: 73px;
            height: 73px;
        }
    }

    &.-arrow-contact {
        background-image: url("./../../../assets/icons/arrow-contact.svg");
        background-repeat: no-repeat;
        background-size: contain;

        width: 18px;
        height: 18px;

        @include breakpoint(md) {
            width: 25px;
            height: 25px;
        }

        @include breakpoint(xl) {
            width: 35px;
            height: 35px;
        }

        //width: 43px;
        //height: 43px;
    }

    &.-navus-logo {
        background-image: url("./../../../assets/icons/navus.svg");
        background-repeat: no-repeat;
        background-size: contain;

        width: 73px;
        height: 14px;
    }

    &.-contact-phone {
        background-image: url("./../../../assets/icons/contact-phone.svg");
        background-repeat: no-repeat;
        background-size: contain;

        width: 15px;
        height: 15px;
    }

    &.-contact-email {
        background-image: url("./../../../assets/icons/contact-envelope.svg");
        background-repeat: no-repeat;
        background-size: contain;

        width: 15px;
        height: 11px;
    }

    &.-small-logo {
        background-image: url("./../../../assets/icons/small-logo.svg");
        background-repeat: no-repeat;
        background-size: contain;

        width: 62px;
        height: 62px;
    }

    &.-white-phone {
        background-image: url("./../../../assets/icons/white-phone.svg");
        background-repeat: no-repeat;
        background-size: contain;

        width: 15px;
        height: 15px;

        @include breakpoint(sm) {
            width: 21px;
            height: 21px;
        }
    }

    &.-white-envelope {
        background-image: url("./../../../assets/icons/white-envelope.svg");
        background-repeat: no-repeat;
        background-size: contain;

        width: 15px;
        height: 11px;

        @include breakpoint(sm) {
            width: 21px;
            height: 15px;
        }
    }

    &.-blue-arrow-right {
        background-image: url("./../../../assets/icons/blue_arrow_right.svg");
        background-repeat: no-repeat;
        background-size: contain;

        width: 20px;
        height: 9px;

        @include breakpoint(sm) {
            width: 40px;
            height: 12px;
        }
    }

    &.-close-menu {
        background-image: url("./../../../assets/icons/x-mark.svg");
        background-repeat: no-repeat;
        background-size: contain;

        width: 25px;
        height: 25px;

        transition: all 0.2s ease-in-out;
    }

    &.-menu-burger {
        background-image: url("./../../../assets/icons/menu.svg");
        background-repeat: no-repeat;
        background-size: contain;

        width: 19px;
        height: 19px;
        transform: translateY(2px);
    }

    &.-arrow-top {
        // background-image: url("./../../../assets/icons/fast-forward.svg");
        background-image: url("./../../../assets/icons/chevron-up.svg");
        background-repeat: no-repeat;
        background-size: contain;

        width: 20px;
        height: 20px;
        // transform: rotate(-90deg);
        opacity: 0.7;

        @include breakpoint(lg) {
            width: 30px;
            height: 30px;
        }
    }
    &.-pointer {
        background-image: url("./../../../assets/icons/location.svg");
        background-repeat: no-repeat;
        background-size: contain;

        width: 20px;
        height: 20px;

        @include breakpoint(sm) {
            width: 24px;
            height: 24px;
        }
    }
}
