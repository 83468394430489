.row.-flex {
    width: 100%;
    display: flex;

    &.-space-betwean {
        justify-content: space-between;
    }

    &.-align-center {
        align-items: center;
    }
}

.col-12 { width: 100%; }
.col-11 { width: 91.66666667%; }
.col-10 { width: 83.33333333%; }
.col-9 { width: 75%; }
.col-8 { width: 66.66666667%; }
.col-7 { width: 58.33333333%; }
.col-6 { width: 50%; }
.col-5 { width: 41.66666667%; }
.col-4 { width: 33.33333333%; }
.col-3 { width: 25%; }
.col-2 { width: 16.66666667%; }
.col-1 { width: 8.33333333%; }