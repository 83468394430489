@font-face {
    font-family: "PF DinDisplay Pro";
    src: url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-LightItalic.eot");
    src: local(
            "./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-LightItalic"
        ),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-LightItalic.eot?#iefix")
            format("embedded-opentype"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-LightItalic.woff2")
            format("woff2"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-LightItalic.woff")
            format("woff"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-LightItalic.ttf")
            format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "PF DinDisplay Pro";
    src: url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Medium.eot");
    src: local("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Medium"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Medium.eot?#iefix")
            format("embedded-opentype"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Medium.woff2")
            format("woff2"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Medium.woff")
            format("woff"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Medium.ttf")
            format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "PF DinDisplay Pro";
    src: url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ExtraThinItalic.eot");
    src: local(
            "./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ExtraThinItalic"
        ),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ExtraThinItalic.eot?#iefix")
            format("embedded-opentype"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ExtraThinItalic.woff2")
            format("woff2"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ExtraThinItalic.woff")
            format("woff"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ExtraThinItalic.ttf")
            format("truetype");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "PF DinDisplay Pro";
    src: url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Bold.eot");
    src: local("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Bold"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Bold.woff2")
            format("woff2"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Bold.woff")
            format("woff"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Bold.ttf")
            format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "PF DinDisplay Pro";
    src: url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ThinItalic.eot");
    src: local(
            "./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ThinItalic"
        ),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ThinItalic.eot?#iefix")
            format("embedded-opentype"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ThinItalic.woff2")
            format("woff2"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ThinItalic.woff")
            format("woff"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ThinItalic.ttf")
            format("truetype");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "PF DinDisplay Pro";
    src: url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Black.eot");
    src: local("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Black"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Black.eot?#iefix")
            format("embedded-opentype"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Black.woff2")
            format("woff2"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Black.woff")
            format("woff"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Black.ttf")
            format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "PF DinDisplay Pro";
    src: url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-BlackItalic.eot");
    src: local(
            "./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-BlackItalic"
        ),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-BlackItalic.eot?#iefix")
            format("embedded-opentype"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-BlackItalic.woff2")
            format("woff2"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-BlackItalic.woff")
            format("woff"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-BlackItalic.ttf")
            format("truetype");
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "PF DinDisplay Pro";
    src: url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-MediumItalic.eot");
    src: local(
            "./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-MediumItalic"
        ),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-MediumItalic.eot?#iefix")
            format("embedded-opentype"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-MediumItalic.woff2")
            format("woff2"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-MediumItalic.woff")
            format("woff"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-MediumItalic.ttf")
            format("truetype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "PF DinDisplay Pro";
    src: url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Light.eot");
    src: local("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Light"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Light.eot?#iefix")
            format("embedded-opentype"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Light.woff2")
            format("woff2"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Light.woff")
            format("woff"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Light.ttf")
            format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "PF DinDisplay Pro";
    src: url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Thin.eot");
    src: local("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Thin"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Thin.eot?#iefix")
            format("embedded-opentype"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Thin.woff2")
            format("woff2"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Thin.woff")
            format("woff"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Thin.ttf")
            format("truetype");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "PF DinDisplay Pro";
    src: url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Italic.eot");
    src: local("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Italic"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Italic.eot?#iefix")
            format("embedded-opentype"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Italic.woff2")
            format("woff2"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Italic.woff")
            format("woff"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Italic.ttf")
            format("truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "PF DinDisplay Pro";
    src: url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Regular.eot");
    src: local("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Regular"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Regular.eot?#iefix")
            format("embedded-opentype"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Regular.woff2")
            format("woff2"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Regular.woff")
            format("woff"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Regular.ttf")
            format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "PF DinDisplay Pro";
    src: url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-BoldItalic.eot");
    src: local(
            "./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-BoldItalic"
        ),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-BoldItalic.eot?#iefix")
            format("embedded-opentype"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-BoldItalic.woff2")
            format("woff2"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-BoldItalic.woff")
            format("woff"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-BoldItalic.ttf")
            format("truetype");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "PF DinDisplay Pro";
    src: url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ExtraThin.eot");
    src: local("PFDinDisplayPro-ExtraThin"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ExtraThin.eot?#iefix")
            format("embedded-opentype"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ExtraThin.woff2")
            format("woff2"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ExtraThin.woff")
            format("woff"),
        url("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ExtraThin.ttf")
            format("truetype");
    font-weight: 100;
    font-style: normal;
}

* {
    font-family: "PF DinDisplay Pro";
}
