#cookie_accept {
    box-sizing: border-box;
    position: fixed;
    z-index: 99999999;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 60px;
    background: $dark-blue;
    justify-content: center;
    align-items: center;
    align-content: center;
    display: none;
    opacity: 0;
    transform: translateY(30px);
    padding: 10px 20px 20px 20px;
    flex-wrap: wrap;

    &.active {
        display: flex;
    }

    p {
        margin-top: 10px;
        text-align: center;
        font-size: 16px;

        a {
            text-decoration: underline;
            transition: color .2s ease-in-out;

             &:hover {
                 color: $text-hover;
             }
        }
    }

    button {
        border: 1px solid transparent;
        border-radius: 5px;
        padding: 3px 15px;

        background: $text-hover;
        color: $white;
        transition: all .2s ease-in-out;
        margin-top: 10px;
        margin-right: 30px;
        margin-left: 30px;


        &:hover {
            border: 1px solid $text-hover;
            background: transparent;
            color: $text-hover;
        }
    }
}
