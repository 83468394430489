.contacts-row {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &.-first {
        // max-width: 1110px;
        // margin: 0 auto;
        padding-top: 43px;
        flex-wrap: wrap;

        max-width: 450px;
        margin: 0 auto;

        @include breakpoint(lg) {
            flex-wrap: nowrap;
            max-width: 100%;
        }
    }

    .contact-form {
        width: 100%;

        @include breakpoint(lg) {
            width: 48%;
        }
    }

    &.-form {
        padding-top: 0px;
        padding-bottom: 40px;
        position: relative;

        @include breakpoint(lg) {
            padding-top: 114px;
            padding-bottom: 114px;
        }

        .col {
            background: linear-gradient(
                266.24deg,
                rgba(20, 156, 255, 0) 0%,
                rgba(41, 22, 255, 0.42) 96.11%
            );
            width: 100%;
            border-radius: 40px;
            min-height: 383px;
            padding: 20px;

            @include breakpoint(md) {
                padding: 42px 53px;
            }

            @media screen and (max-width: 991px) {
                padding-right: 20px;
                padding-bottom: 0;
            }

            .title {
                font-size: 25px;
                line-height: 35px;
                margin-bottom: 30px;

                @include breakpoint(md) {
                    font-size: 30px;
                    line-height: 40px;
                }

                @include breakpoint(xl) {
                    font-size: 50px;
                    line-height: 60px;
                }
            }
        }

        .card {
            background: url("./../../assets/bg/contact-right-card.svg");

            // background-repeat: no-repeat;
            background-size: cover;
            background-position: 99% 0%;
            border-radius: 40px;
            width: calc(100% - 20px);
            position: relative;
            top: 20px;
            right: 0;
            padding: 20px 0 20px 20px;
            margin: 0 0 0 auto;

            @include breakpoint(md) {
                padding: 42px 0 42px 53px;
                width: calc(100% - 53px);
            }

            @include breakpoint(lg) {
                position: absolute;
                top: calc(98px + 114px);
                width: calc(49% - 52px);
            }

            .row {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &.-right {
                    justify-content: flex-end;
                }

                &.-space-between {
                    justify-content: space-between;
                    align-items: baseline;
                }
            }

            .company-name {
                font-weight: bold;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.09em;
                padding-top: 41px;
                padding-bottom: 28px;
            }

            .contact-info {
                font-size: 14px;
                line-height: 22px;

                @include breakpoint(sm) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            .phone {
                padding-top: 37px;
            }

            .email {
                padding-top: 15px;
            }

            .phone,
            .email {
                display: flex;
                align-items: center;
                font-size: 18px;
                line-height: 15px;

                .icon {
                    margin-right: 7px;
                    line-height: 15px;
                }

                @include breakpoint(sm) {
                    font-size: 27px;
                    line-height: 24px;

                    .icon {
                        margin-right: 13px;
                        line-height: 24px;
                    }
                }
            }

            .social {
                padding-top: 26px;
                display: flex;

                .icon.-facebook {
                    width: 12px;
                    height: 24px;
                    margin-right: 16px;
                    cursor: pointer;
                }
                .icon.-linkedin {
                    width: 24px;
                    height: 24px;
                    cursor: pointer;
                }

                @include breakpoint(sm) {
                    .icon.-facebook {
                        width: 16.64px;
                        height: 33.28px;
                        margin-right: 33px;
                    }
                    .icon.-linkedin {
                        width: 33.28px;
                        height: 33.28px;
                    }
                }
            }
        }
    }

    .contact {
        width: calc(100% - 10px);
        padding-right: 10px;
        padding-bottom: 40px;

        @include breakpoint(xs) {
            width: auto;
        }

        @include breakpoint(lg) {
            width: auto;
            padding-bottom: 0px;
        }

        .job-title {
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.09em;
            text-transform: uppercase;
        }

        .full-name {
            font-weight: bold;
            font-size: 17px;
            line-height: 24px;
        }

        .phone,
        .email {
            text-decoration: none;
            display: flex;
            align-items: center;

            .icon {
                margin-right: 9px;
            }

            outline: none;
        }

        .phone {
            font-size: 16px;
            line-height: 24px;
            color: $white;
        }

        .email {
            font-size: 16px;
            line-height: 24px;
            color: $blue;
        }
    }

    input[type="text"],
    select,
    textarea {
        background: transparent;
        border: 0;
        border-bottom: 1px solid $light-blue;
        padding: 6px 0;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 30px;
    }

    textarea,
    select,
    input[type="text"],
    .action-row {
        width: 100%;

        @include breakpoint(lg) {
            width: calc(100% - 30px);
        }
    }

    input[type="text"] {
        margin-right: 0px;
        color: $white;
        width: calc(100% - 15px);

        @include breakpoint(lg) {
            margin-right: 30px;
        }
    }

    .row .col-6:last-child {
        text-align: right;
    }

    input[type="text"]::placeholder {
        color: $light-blue;
    }

    input[type="text"]:valid,
    input[type="text"]:focus {
        border-bottom: 1px solid $white;
    }

    // select {
    //     color: $light-blue;
    //     background: transparent;
    //     border: 0;
    //     border-bottom: 1px solid $light-blue;

    //     option {
    //         color: rgb(63, 63, 63);
    //         font-size: 16px;
    //     }
    // }

    /*the container must be positioned relative:*/
    .custom-select {
        position: relative;
        margin-right: 0px;

        @include breakpoint(lg) {
            margin-right: 15px;
        }
    }

    .custom-select select {
        display: none; /*hide original SELECT element:*/
    }

    .select-selected:after {
        position: absolute;
        content: "";
        bottom: 16px;
        right: 0px;
        width: 9px;
        height: 9px;
        background: url('./../../assets/icons/dropdown_arrow_down.svg');
        background-repeat: no-repeat;
        background-size: contain;
        transition: all .1s ease-in-out;
    }

    .select-items div,.select-selected {
        color: $light-blue;
        cursor: pointer;
        user-select: none;
    }

    .select-selected {
        border: 0;
        border-bottom: 1px solid $light-blue;
        padding: 6px 0;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 30px;
        color: $light-blue;

        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .select-items div {
        padding: 8px 16px;
        color: $light-blue;
    }

    .select-items {
        position: absolute;
        background-color:  rgba(1, 2, 42);
        top: 100%;
        left: 0;
        right: 0;
        z-index: 99;
    }

    .select-hide {
        display: none;
    }

    .select-items div:hover, .same-as-selected {
        background-color: #000000;
    }

    textarea {
        color: $white;
        margin-right: 0px;
        width: calc(100% - 15px);
    }

    textarea::placeholder {
        color: $light-blue;
    }

    textarea:valid,
    textarea:focus {
        border-bottom: 1px solid $white;
    }

    input[type="checkbox"] {
        margin-right: 8px;
        width: 18px;
        height: 18px;
    }

    .checkbox-row {
        span {
            font-size: 15px;
            line-height: 24px;
            vertical-align: bottom;
        }

        input {
            cursor: pointer;
        }
    }

    .send-button {
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.09em;
        text-decoration: none;
        color: $blue;
        text-transform: uppercase;
        min-width: 90px;
        text-align: right;
        background: transparent;
        border: 0;

        div {
            display: inline-block;
            margin-left: 0px;

            @include breakpoint(sm) {
                margin-left: 14px;
            }
        }
    }

    .find-us {
        font-weight: 600;
        font-size: 20px;

        @include breakpoint(sm) {
            font-size: 24px;
        }

        a {
            display: flex;
            transition: color 0.1s ease-in-out;

            .icon {
                transition: filter 0.1s ease-in-out;
                margin-right: 5px;

                @include breakpoint(sm) {
                    margin-right: 10px;
                }
            }

            &:hover {
                color: $text-hover;

                .icon {
                    filter: $svg-hover-filter;
                }

                svg {
                    fill: $text-hover;
                }
            }
        }
    }

    .with-error {
        position: relative;

        .error-message {
            color: $error-message-color;
            font-size: 12px;
            position: absolute;
            left: 0px;
            top: 60%;
            text-align: left;
            //bottom: 13px;

            &.-priv {
                top: 100%;
            }

            @media screen and (max-width: 480px) {
                font-size: 8px;
            }

            &.-second {
                left: 15px;

                @include breakpoint(lg) {
                    left: 0px;
                }
            }
        }
    }

    .action-row.with-error {
        .error-message {
            position: absolute;
            left: 0px;
            bottom: -15px;
        }
    }

    .success-message {
        padding-top: 10px;
        color: $white;
        font-size: 12px;
    }
}

.contacts-row.-form {
    padding-top: 80px;
}

.contacts-row.-form .card {
    top: calc(98px + 90px);

    @media screen and (max-width: 991px) {
        top: 50px;
    }
    @media screen and (max-width: 767px) {
        top: 20px;
    }
}
