.scroll-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
    display: none;
    opacity: 0;
    cursor: pointer;

    &.active {
        display: block;
        animation: fadeInElement 0.5s 0.2s forwards;
    }

    .icon:hover {
        animation: jumpElement 1s infinite;
    }
}

@keyframes fadeInElement {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.7;
    }
}
@keyframes jumpElement {
    0% {
        opacity: 0.7;
        transform: translateY(0px);
    }
    50% {
        opacity: 0.7;
        transform: translateY(-5px);
    }
    100% {
        opacity: 0.7;
        transform: translateY(0px);
    }
}
