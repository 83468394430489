.preeloader {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #060233;
  z-index: 999999999999;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-out;
}

.preeloader img {
  opacity: 0;
  max-width: 400px;
  -webkit-animation: logoFade 0.8s 0.8s forwards;
          animation: logoFade 0.8s 0.8s forwards;
}

@media screen and (max-width: 575px) {
  .preeloader img {
    width: 80%;
  }
}

#home .preeloader {
  display: flex;
}

@-webkit-keyframes logoFade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes logoFade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  line-height: normal;
}

/* make sure to set some focus styles for accessibility */

:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: normal;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
  outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
  outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

*:focus {
  outline: none;
}

* {
  -webkit-tap-highlight-color: transparent;
  outline: none !important;
}

a {
  text-decoration: none;
  color: #fff;
}

.grecaptcha-badge {
  display: none !important;
}

@font-face {
  font-family: "PF DinDisplay Pro";
  src: url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-LightItalic.eot");
  src: local("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-LightItalic"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-LightItalic.eot") format("embedded-opentype"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-LightItalic.woff2") format("woff2"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-LightItalic.woff") format("woff"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "PF DinDisplay Pro";
  src: url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Medium.eot");
  src: local("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Medium"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Medium.eot") format("embedded-opentype"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Medium.woff2") format("woff2"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Medium.woff") format("woff"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "PF DinDisplay Pro";
  src: url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ExtraThinItalic.eot");
  src: local("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ExtraThinItalic"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ExtraThinItalic.eot") format("embedded-opentype"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ExtraThinItalic.woff2") format("woff2"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ExtraThinItalic.woff") format("woff"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ExtraThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "PF DinDisplay Pro";
  src: url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Bold.eot");
  src: local("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Bold"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Bold.eot") format("embedded-opentype"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Bold.woff2") format("woff2"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Bold.woff") format("woff"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "PF DinDisplay Pro";
  src: url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ThinItalic.eot");
  src: local("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ThinItalic"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ThinItalic.eot") format("embedded-opentype"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ThinItalic.woff2") format("woff2"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ThinItalic.woff") format("woff"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "PF DinDisplay Pro";
  src: url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Black.eot");
  src: local("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Black"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Black.eot") format("embedded-opentype"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Black.woff2") format("woff2"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Black.woff") format("woff"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "PF DinDisplay Pro";
  src: url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-BlackItalic.eot");
  src: local("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-BlackItalic"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-BlackItalic.eot") format("embedded-opentype"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-BlackItalic.woff2") format("woff2"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-BlackItalic.woff") format("woff"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "PF DinDisplay Pro";
  src: url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-MediumItalic.eot");
  src: local("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-MediumItalic"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-MediumItalic.eot") format("embedded-opentype"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-MediumItalic.woff2") format("woff2"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-MediumItalic.woff") format("woff"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "PF DinDisplay Pro";
  src: url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Light.eot");
  src: local("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Light"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Light.eot") format("embedded-opentype"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Light.woff2") format("woff2"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Light.woff") format("woff"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "PF DinDisplay Pro";
  src: url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Thin.eot");
  src: local("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Thin"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Thin.eot") format("embedded-opentype"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Thin.woff2") format("woff2"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Thin.woff") format("woff"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "PF DinDisplay Pro";
  src: url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Italic.eot");
  src: local("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Italic"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Italic.eot") format("embedded-opentype"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Italic.woff2") format("woff2"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Italic.woff") format("woff"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "PF DinDisplay Pro";
  src: url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Regular.eot");
  src: local("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Regular"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Regular.eot") format("embedded-opentype"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Regular.woff2") format("woff2"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Regular.woff") format("woff"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PF DinDisplay Pro";
  src: url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-BoldItalic.eot");
  src: local("./../../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-BoldItalic"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-BoldItalic.eot") format("embedded-opentype"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-BoldItalic.woff2") format("woff2"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-BoldItalic.woff") format("woff"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "PF DinDisplay Pro";
  src: url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ExtraThin.eot");
  src: local("PFDinDisplayPro-ExtraThin"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ExtraThin.eot") format("embedded-opentype"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ExtraThin.woff2") format("woff2"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ExtraThin.woff") format("woff"), url("./../assets/fonts/PFDinDisplayPro/PFDinDisplayPro-ExtraThin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

* {
  font-family: "PF DinDisplay Pro";
}

/*!
 * fullPage 3.1.0
 * https://github.com/alvarotrigo/fullPage.js
 *
 * @license GPLv3 for open source use only
 * or Fullpage Commercial License for commercial use
 * http://alvarotrigo.com/fullPage/pricing/
 *
 * Copyright (C) 2018 http://alvarotrigo.com/fullPage - A project by Alvaro Trigo
 */

html.fp-enabled,
.fp-enabled body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  /*Avoid flicker on slides transitions for mobile phones #336 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.fp-section {
  position: relative;
  /* Safari<=5 Android<=3 */
  /* <=28 */
  box-sizing: border-box;
}

.fp-slide {
  float: left;
}

.fp-slide,
.fp-slidesContainer {
  height: 100%;
  display: block;
}

.fp-slides {
  z-index: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
  /* Safari<=6 Android<=4.3 */
  transition: all 0.3s ease-out;
}

.fp-section.fp-table,
.fp-slide.fp-table {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.fp-tableCell {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

.fp-slidesContainer {
  float: left;
  position: relative;
}

.fp-controlArrow {
  -webkit-user-select: none;
  /* webkit (safari, chrome) browsers */
  -moz-user-select: none;
  /* mozilla browsers */
  -khtml-user-select: none;
  /* webkit (konqueror) browsers */
  -ms-user-select: none;
  /* IE10+ */
  position: absolute;
  z-index: 4;
  top: 50%;
  cursor: pointer;
  width: 0;
  height: 0;
  border-style: solid;
  margin-top: -38px;
  transform: translate3d(0, 0, 0);
}

.fp-controlArrow.fp-prev {
  left: 15px;
  width: 0;
  border-width: 38.5px 34px 38.5px 0;
  border-color: transparent #fff transparent transparent;
}

.fp-controlArrow.fp-next {
  right: 15px;
  border-width: 38.5px 0 38.5px 34px;
  border-color: transparent transparent transparent #fff;
}

.fp-scrollable {
  overflow: hidden;
  position: relative;
}

.fp-scroller {
  overflow: hidden;
}

.iScrollIndicator {
  border: 0 !important;
}

.fp-notransition {
  transition: none !important;
}

#fp-nav {
  position: fixed;
  z-index: 100;
  top: 50%;
  opacity: 1;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translate3d(0, -50%, 0);
}

#fp-nav.fp-right {
  right: 17px;
}

#fp-nav.fp-left {
  left: 17px;
}

.fp-slidesNav {
  position: absolute;
  z-index: 4;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  left: 0 !important;
  right: 0;
  margin: 0 auto !important;
}

.fp-slidesNav.fp-bottom {
  bottom: 17px;
}

.fp-slidesNav.fp-top {
  top: 17px;
}

#fp-nav ul,
.fp-slidesNav ul {
  margin: 0;
  padding: 0;
}

#fp-nav ul li,
.fp-slidesNav ul li {
  display: block;
  width: 14px;
  height: 13px;
  margin: 7px;
  position: relative;
}

.fp-slidesNav ul li {
  display: inline-block;
}

#fp-nav ul li a,
.fp-slidesNav ul li a {
  display: block;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
}

#fp-nav ul li a.active span,
.fp-slidesNav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li:hover a.active span {
  height: 12px;
  width: 12px;
  margin: -6px 0 0 -6px;
  border-radius: 100%;
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  height: 4px;
  width: 4px;
  border: 0;
  background: #333;
  left: 50%;
  top: 50%;
  margin: -2px 0 0 -2px;
  transition: all 0.1s ease-in-out;
}

#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span {
  width: 10px;
  height: 10px;
  margin: -5px 0px 0px -5px;
}

#fp-nav ul li .fp-tooltip {
  position: absolute;
  top: -2px;
  color: #fff;
  font-size: 14px;
  font-family: arial, helvetica, sans-serif;
  white-space: nowrap;
  max-width: 220px;
  overflow: hidden;
  display: block;
  opacity: 0;
  width: 0;
  cursor: pointer;
}

#fp-nav ul li:hover .fp-tooltip,
#fp-nav.fp-show-active a.active + .fp-tooltip {
  transition: opacity 0.2s ease-in;
  width: auto;
  opacity: 1;
}

#fp-nav ul li .fp-tooltip.fp-right {
  right: 20px;
}

#fp-nav ul li .fp-tooltip.fp-left {
  left: 20px;
}

.fp-auto-height.fp-section,
.fp-auto-height .fp-slide,
.fp-auto-height .fp-tableCell {
  height: auto !important;
}

.fp-responsive .fp-auto-height-responsive.fp-section,
.fp-responsive .fp-auto-height-responsive .fp-slide,
.fp-responsive .fp-auto-height-responsive .fp-tableCell {
  height: auto !important;
}

/*Only display content to screen readers*/

.fp-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.icon.-facebook {
  background-image: url("./../assets/icons/facebook.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 8px;
  height: 16px;
  transition: all 0.2s ease-in-out;
}

.icon.-facebook:hover {
  filter: invert(45%) sepia(74%) saturate(692%) hue-rotate(177deg) brightness(90%) contrast(91%);
}

.icon.-linkedin {
  background-image: url("./../assets/icons/linkedin.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
  transition: all 0.2s ease-in-out;
}

.icon.-linkedin:hover {
  filter: invert(45%) sepia(74%) saturate(692%) hue-rotate(177deg) brightness(90%) contrast(91%);
}

.icon.-globe {
  background-image: url("./../assets/icons/global.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
  transition: all 0.2s ease-in-out;
}

.icon.-globe:hover {
  filter: invert(45%) sepia(74%) saturate(692%) hue-rotate(177deg) brightness(90%) contrast(91%);
}

.icon.-dropdown-arrow-down {
  background-image: url("./../assets/icons/dropdown_arrow_down.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 9px;
  height: 5px;
}

.icon.-envelope {
  background-image: url("./../assets/icons/envelope.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 18px;
  height: 14px;
}

.icon.-circle-arrow {
  background-image: url("./../assets/icons/arrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 15px;
  height: 15px;
}

.icon.-blue-arrow {
  background-image: url("./../assets/icons/blue_arrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 12px;
}

.icon.-arrow-left {
  background-image: url("./../assets/icons/arrow-left.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 43px;
  height: 43px;
}

@media (min-width: 992px) {
  .icon.-arrow-left {
    width: 73px;
    height: 73px;
  }
}

.icon.-arrow-right {
  background-image: url("./../assets/icons/arrow-right.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 43px;
  height: 43px;
}

@media (min-width: 992px) {
  .icon.-arrow-right {
    width: 73px;
    height: 73px;
  }
}

.icon.-arrow-contact {
  background-image: url("./../assets/icons/arrow-contact.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
}

@media (min-width: 768px) {
  .icon.-arrow-contact {
    width: 25px;
    height: 25px;
  }
}

@media (min-width: 1250px) {
  .icon.-arrow-contact {
    width: 35px;
    height: 35px;
  }
}

.icon.-navus-logo {
  background-image: url("./../assets/icons/navus.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 73px;
  height: 14px;
}

.icon.-contact-phone {
  background-image: url("./../assets/icons/contact-phone.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 15px;
  height: 15px;
}

.icon.-contact-email {
  background-image: url("./../assets/icons/contact-envelope.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 15px;
  height: 11px;
}

.icon.-small-logo {
  background-image: url("./../assets/icons/small-logo.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 62px;
  height: 62px;
}

.icon.-white-phone {
  background-image: url("./../assets/icons/white-phone.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 15px;
  height: 15px;
}

@media (min-width: 576px) {
  .icon.-white-phone {
    width: 21px;
    height: 21px;
  }
}

.icon.-white-envelope {
  background-image: url("./../assets/icons/white-envelope.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 15px;
  height: 11px;
}

@media (min-width: 576px) {
  .icon.-white-envelope {
    width: 21px;
    height: 15px;
  }
}

.icon.-blue-arrow-right {
  background-image: url("./../assets/icons/blue_arrow_right.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 9px;
}

@media (min-width: 576px) {
  .icon.-blue-arrow-right {
    width: 40px;
    height: 12px;
  }
}

.icon.-close-menu {
  background-image: url("./../assets/icons/x-mark.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
  transition: all 0.2s ease-in-out;
}

.icon.-menu-burger {
  background-image: url("./../assets/icons/menu.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 19px;
  height: 19px;
  transform: translateY(2px);
}

.icon.-arrow-top {
  background-image: url("./../assets/icons/chevron-up.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  opacity: 0.7;
}

@media (min-width: 992px) {
  .icon.-arrow-top {
    width: 30px;
    height: 30px;
  }
}

.icon.-pointer {
  background-image: url("./../assets/icons/location.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

@media (min-width: 576px) {
  .icon.-pointer {
    width: 24px;
    height: 24px;
  }
}

.main-menu {
  height: 41px;
  padding: 20px;
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 768px) {
  .main-menu {
    height: 62px;
    padding: 29px 80px 29px 80px;
  }
}

@media (min-width: 1250px) {
  .main-menu {
    height: 62px;
    padding: 29px 165px 29px 165px;
  }
}

.main-menu .logo {
  position: relative;
  height: 100%;
  width: 120px;
  background-image: url("./../assets/logo.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.main-menu .logo a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

@media (min-width: 768px) {
  .main-menu .logo {
    height: 100%;
    width: 181px;
  }
}

.main-menu .menu .button-container {
  display: none;
  padding: 0 10px;
}

@media (min-width: 992px) {
  .main-menu .menu .button-container {
    display: inline-block;
  }
}

@media (min-width: 1250px) {
  .main-menu .menu .button-container {
    padding: 0 20px;
  }
}

.main-menu .menu .button-container .button {
  text-decoration: none;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  position: relative;
  transition: color 0.2s ease-in-out;
}

.main-menu .menu .button-container .button:hover {
  color: #59a6de;
}

.main-menu .menu .button-container .button.active::before {
  content: "";
  position: absolute;
  top: -51px;
  left: 0;
  width: 100%;
  height: 5px;
  background: #ffffff;
}

.main-menu .menu .action {
  display: inline-block;
  padding-right: 16px;
  position: relative;
}

.main-menu .menu .action.lang {
  transition: color 0.2s ease-in-out;
}

.main-menu .menu .action.lang .icon {
  transition: color 0.2s ease-in-out;
}

.main-menu .menu .action.lang:hover .language {
  color: #59a6de;
}

.main-menu .menu .action.lang:hover .language .icon {
  filter: invert(45%) sepia(74%) saturate(692%) hue-rotate(177deg) brightness(90%) contrast(91%);
}

.main-menu .menu .action .language {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  cursor: pointer;
}

.main-menu .menu .action .language span,
.main-menu .menu .action .language div:first-child {
  padding-right: 7px;
}

.main-menu .menu .action .vertical-line {
  width: 1px;
  height: 25px;
  background-color: #ffffff;
  opacity: 0.57;
  transform: translateY(5px);
}

.main-menu .menu .action .language-swich {
  position: absolute;
  top: calc(100% + 10px);
  right: 16px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s linear;
}

.main-menu .menu .action .language-swich li {
  padding: 5px 20px;
}

.main-menu .menu .action .language-swich li:first-child {
  padding-top: 20px;
}

.main-menu .menu .action .language-swich li:last-child {
  padding-bottom: 20px;
}

.main-menu .menu .action .language-swich li a {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.07em;
  color: #ffffff;
  transition: color 0.2s linear;
}

.main-menu .menu .action .language-swich li a:hover {
  color: #59a6de;
}

.main-menu .menu .action.lang:hover .language-swich {
  max-height: 150px;
}

.main-menu .menu .action.for-dextop {
  display: none;
}

@media (min-width: 992px) {
  .main-menu .menu .action.for-dextop {
    display: inline-block;
  }
}

@media (min-width: 992px) {
  .main-menu .menu .action.for-mobile {
    display: none;
  }
}

#home .main-menu.fixed {
  position: fixed;
  top: 0;
  width: calc(100% - (20px * 2));
  z-index: 999;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 768px) {
  #home .main-menu.fixed {
    width: calc(100% - (80px * 2));
  }
}

@media (min-width: 1250px) {
  #home .main-menu.fixed {
    width: calc(100% - (165px * 2));
  }
}

.sticky-btn {
  position: fixed;
  z-index: 999;
  top: 40%;
  display: block;
  transform: translateY(-70%);
  padding: 19px 10px 19px 11px;
  background: linear-gradient(135deg, rgba(0, 222, 236, 0.32) 0%, #334e8d 100%);
  border-radius: 8px 0px 0px 8px;
  cursor: pointer;
  right: 0;
}

@media screen and (max-width: 991px) {
  .sticky-btn {
    top: 50%;
    transform: translateY(-30%);
  }
}

.sticky-btn .text {
  text-transform: uppercase;
  padding: 6px 0px 7px 0px;
  font-weight: bold;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.14em;
  -ms-writing-mode: tb-lr;
      writing-mode: vertical-lr;
  transform: rotate(180deg);
}

@media (min-width: 768px) {
  .sticky-btn .text {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1em;
    padding: 8px 0px 9px 0px;
  }
}

@media (min-width: 1250px) {
  .sticky-btn .text {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.14em;
    padding: 10px 0px 11px 0px;
  }
}

.sticky-btn .icon.-envelope {
  height: 14px;
  width: 10px;
}

.sticky-btn .icon.-circle-arrow {
  width: 11px;
  height: 11px;
}

@media (min-width: 768px) {
  .sticky-btn .icon.-envelope {
    height: 16px;
    width: 12px;
  }

  .sticky-btn .icon.-circle-arrow {
    width: 13px;
    height: 13px;
  }
}

@media (min-width: 1250px) {
  .sticky-btn .icon.-envelope {
    height: 18px;
    width: 14px;
  }

  .sticky-btn .icon.-circle-arrow {
    width: 15px;
    height: 15px;
  }
}

.row.-flex {
  width: 100%;
  display: flex;
}

.row.-flex.-space-betwean {
  justify-content: space-between;
}

.row.-flex.-align-center {
  align-items: center;
}

.col-12 {
  width: 100%;
}

.col-11 {
  width: 91.66666667%;
}

.col-10 {
  width: 83.33333333%;
}

.col-9 {
  width: 75%;
}

.col-8 {
  width: 66.66666667%;
}

.col-7 {
  width: 58.33333333%;
}

.col-6 {
  width: 50%;
}

.col-5 {
  width: 41.66666667%;
}

.col-4 {
  width: 33.33333333%;
}

.col-3 {
  width: 25%;
}

.col-2 {
  width: 16.66666667%;
}

.col-1 {
  width: 8.33333333%;
}

.mobile-menu {
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  width: 100%;
  top: 0;
  right: -400px;
  z-index: 999;
  transition: all 0.5s ease-in-out;
  background: linear-gradient(rgba(2, 0, 36, 0.98) 0%, rgba(0, 0, 0, 0.98) 100%);
}

@media screen and (min-width: 400px) {
  .mobile-menu {
    width: 300px;
  }
}

@media (min-width: 992px) {
  .mobile-menu {
    display: none;
  }
}

.mobile-menu.active {
  right: 0;
}

.mobile-menu .close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
}

.mobile-menu .menu-inner {
  padding: 40px 0;
  width: 100%;
  box-sizing: border-box;
}

.mobile-menu .menu-inner .title {
  width: 100%;
  font-size: 50px;
  text-align: center;
  text-align: center;
  margin-bottom: 40px;
}

.mobile-menu .menu-inner .menu-buttons {
  padding: 0 50px;
}

.mobile-menu .menu-inner .menu-buttons .button-container {
  padding: 8px 0;
}

.mobile-menu .menu-inner .menu-buttons .button-container a {
  transition: color 0.2s ease-in-out;
}

.mobile-menu .menu-inner .menu-buttons .button-container a:hover {
  color: #59a6de;
}

.mobile-menu .menu-inner .menu-buttons .button-container a.active {
  color: #ffffff;
  padding-left: 15px;
  position: relative;
}

.mobile-menu .menu-inner .menu-buttons .button-container a.active::before {
  content: "";
  width: 10px;
  height: 2px;
  background: #ffffff;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: background 0.2s ease-in-out;
}

.mobile-menu .menu-inner .menu-buttons .button-container a.active:hover {
  color: #59a6de;
}

.mobile-menu .menu-inner .menu-buttons .button-container a.active:hover::before {
  background: #59a6de;
}

.mobile-menu .menu-inner .social {
  display: flex;
  padding: 0 50px;
  margin-top: 40px;
}

.mobile-menu .menu-inner .social a {
  margin-right: 15px;
}

.mobile-menu .menu-inner .social a .icon.-facebook {
  width: calc(8px * 1.3);
  height: calc(16px * 1.3);
}

.mobile-menu .menu-inner .social a .icon.-linkedin {
  width: calc(16px * 1.3);
  height: calc(16px * 1.3);
}

.mobile-menu .menu-inner .contacts {
  margin-top: 40px;
  padding: 0 50px;
}

.mobile-menu .menu-inner .contacts .phone,
.mobile-menu .menu-inner .contacts .email {
  display: flex;
  padding-bottom: 20px;
  align-items: center;
}

.mobile-menu .menu-inner .contacts .phone .icon,
.mobile-menu .menu-inner .contacts .email .icon {
  margin-right: 10px;
}

.scroll-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  display: none;
  opacity: 0;
  cursor: pointer;
}

.scroll-top.active {
  display: block;
  -webkit-animation: fadeInElement 0.5s 0.2s forwards;
          animation: fadeInElement 0.5s 0.2s forwards;
}

.scroll-top .icon:hover {
  -webkit-animation: jumpElement 1s infinite;
          animation: jumpElement 1s infinite;
}

@-webkit-keyframes fadeInElement {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.7;
  }
}

@keyframes fadeInElement {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.7;
  }
}

@-webkit-keyframes jumpElement {
  0% {
    opacity: 0.7;
    transform: translateY(0px);
  }

  50% {
    opacity: 0.7;
    transform: translateY(-5px);
  }

  100% {
    opacity: 0.7;
    transform: translateY(0px);
  }
}

@keyframes jumpElement {
  0% {
    opacity: 0.7;
    transform: translateY(0px);
  }

  50% {
    opacity: 0.7;
    transform: translateY(-5px);
  }

  100% {
    opacity: 0.7;
    transform: translateY(0px);
  }
}

#cookie_accept {
  box-sizing: border-box;
  position: fixed;
  z-index: 99999999;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 60px;
  background: #060233;
  justify-content: center;
  align-items: center;
  align-content: center;
  display: none;
  opacity: 0;
  transform: translateY(30px);
  padding: 10px 20px 20px 20px;
  flex-wrap: wrap;
}

#cookie_accept.active {
  display: flex;
}

#cookie_accept p {
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
}

#cookie_accept p a {
  text-decoration: underline;
  transition: color 0.2s ease-in-out;
}

#cookie_accept p a:hover {
  color: #59a6de;
}

#cookie_accept button {
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 3px 15px;
  background: #59a6de;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
  margin-top: 10px;
  margin-right: 30px;
  margin-left: 30px;
}

#cookie_accept button:hover {
  border: 1px solid #59a6de;
  background: transparent;
  color: #59a6de;
}

.banner {
  box-sizing: border-box;
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: calc(100% - 10px);
  text-align: right;
}

@media (min-width: 768px) {
  .banner {
    bottom: 5px;
  }
}

.banner img {
  max-width: 100%;
}

.main-background {
  background-color: #000000;
  color: #ffffff;
  font-family: PF DinDisplay Pro;
}

.main-background #fullPageScroll section {
  height: 100vh;
}

body {
  max-width: 1920px !important;
  margin: 0 auto !important;
  position: relative;
}

#topMenuRow {
  position: relative;
  z-index: 9;
}

.slick-initialized .slick-slide {
  padding: 0 9px;
}

@media (min-width: 992px) {
  .slick-initialized .slick-slide {
    padding: 0 18px;
  }
}

#section-1 .content {
  z-index: -1;
}

.section-content .content.-first {
  z-index: -1;
}

.section-content {
  position: relative;
  height: 100%;
  width: 100%;
}

.section-content .header {
  height: calc(100% - 60px);
  width: 100%;
  margin-bottom: 60px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.section-content .header.-bg {
  background-image: url("./../assets/bg/bg_top.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  width: 100%;
}

.section-content .content {
  height: 100%;
  width: calc(100% - 20px - 20px);
  max-width: 1110px;
  padding: 0 20px;
  margin: 0 auto;
  position: relative;
}

@media (min-width: 768px) {
  .section-content .content {
    padding: 0 80px;
    width: calc(100% - 80px - 80px);
  }
}

@media (min-width: 1250px) {
  .section-content .content {
    padding: 0 165px;
    width: calc(100% - 165px - 165px);
  }
}

.section-content .content h1 {
  position: absolute;
  top: 25%;
  width: calc(100% - 40px);
  left: page-padding;
  font-size: 30px;
  line-height: 40px;
  max-width: calc(100% - 20px - 20px - 20px);
}

@media (min-width: 768px) {
  .section-content .content h1 {
    width: calc(100% - 160px);
    max-width: calc( 100% - 80px - 80px );
    font-size: 50px;
    line-height: 64px;
    left: 80px;
  }
}

@media (min-width: 992px) {
  .section-content .content h1 {
    width: 952px;
    font-size: 60px;
    line-height: 74px;
  }
}

@media (min-width: 1250px) {
  .section-content .content h1 {
    max-width: calc( 100% - 165px - 165px );
    left: 165px;
  }
}

.section-content.content-padding {
  max-width: 1110px;
  margin: 0 auto;
  width: calc(100% - 20px - 20px - 40px);
  padding: 0 20px;
}

@media (min-width: 768px) {
  .section-content.content-padding {
    width: calc(100% - 80px - 80px);
    padding: 0 80px;
  }
}

@media (min-width: 1250px) {
  .section-content.content-padding {
    width: calc(100% - 165px - 165px);
    padding: 0 165px;
  }
}

.section-content.content-padding .content {
  display: flex;
  align-items: center;
  padding: 0;
  width: 100%;
}

.section-content.content-padding .content .left,
.section-content.content-padding .content .right {
  width: 50%;
}

.section-content.content-padding .content .left {
  min-width: 240px;
}

@media (min-width: 576px) {
  .section-content.content-padding .content .left {
    min-width: 380px;
  }
}

.section-content.content-padding .content .right {
  max-width: 50%;
  padding-right: 15px;
}

@media screen and (max-width: 700px) {
  .section-content.content-padding .content {
    flex-wrap: wrap;
    align-content: center;
  }

  .section-content.content-padding .content .left,
  .section-content.content-padding .content .right {
    width: 100%;
    margin-bottom: 10px;
  }

  .section-content.content-padding .content .right {
    max-width: 100%;
  }
}

@media (min-width: 1250px) {
  .section-content.content-padding .content .left {
    min-width: 400px;
  }
}

@media (min-width: 1440px) {
  .section-content.content-padding .content .left,
  .section-content.content-padding .content .right {
    width: 50%;
  }

  .section-content.content-padding .content .left {
    min-width: 600px;
  }

  .section-content.content-padding .content .right {
    max-width: 50%;
  }
}

.section-content.content-padding .content .left .box {
  position: relative;
  width: 124px;
  height: 124px;
  margin: 0 40px 0 auto;
  /* Linear */
  background: linear-gradient(270deg, rgba(20, 156, 255, 0) 0%, #334e8d 100%);
  border-radius: 40px;
}

@media screen and (min-width: 330px) {
  .section-content.content-padding .content .left .box {
    width: 204px;
    height: 204px;
    margin: 0 40px 0 auto;
  }
}

@media (min-width: 576px) {
  .section-content.content-padding .content .left .box {
    width: 284px;
    height: 284px;
    margin: 0 80px 0 auto;
  }
}

@media (min-width: 768px) {
  .section-content.content-padding .content .left .box {
    margin: 0;
  }
}

@media (min-width: 992px) {
  .section-content.content-padding .content .left .box {
    width: 350px;
    height: 350px;
  }
}

@media (min-width: 1250px) {
  .section-content.content-padding .content .left .box {
    width: 384px;
    height: 384px;
  }
}

@media (min-width: 1440px) {
  .section-content.content-padding .content .left .box {
    width: 484px;
    height: 484px;
  }
}

.section-content.content-padding .content .left .title {
  position: absolute;
  width: 80%;
  height: 168px;
  right: -23px;
  top: 22px;
  font-size: 20px;
  line-height: 24px;
  text-align: right;
}

@media (min-width: 576px) {
  .section-content.content-padding .content .left .title {
    font-size: 50px;
    line-height: 64px;
    right: -53px;
    top: 42px;
    width: 343px;
  }
}

@media (min-width: 1250px) {
  .section-content.content-padding .content .left .title {
    font-size: 60px;
    line-height: 74px;
  }
}

@media (min-width: 1440px) {
  .section-content.content-padding .content .left .title {
    font-size: 70px;
    line-height: 84px;
  }
}

.section-content.content-padding .content .left img {
  position: absolute;
  right: -23px;
  bottom: -28px;
  width: calc(415px / 3.5);
  height: calc(253px / 3.5);
}

@media (min-width: 576px) {
  .section-content.content-padding .content .left img {
    width: calc(415px / 1.6);
    height: calc(253px / 1.6);
    right: -53px;
    bottom: -48px;
  }
}

@media (min-width: 1250px) {
  .section-content.content-padding .content .left img {
    width: calc(415px / 1.2);
    height: calc(253px / 1.2);
  }
}

@media (min-width: 1440px) {
  .section-content.content-padding .content .left img {
    width: 415px;
    height: 253px;
  }
}

.section-content.content-padding .content .right .title {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  margin-bottom: 13px;
}

.section-content.content-padding .content .right .description {
  max-width: 645px;
  line-height: 22px;
  font-size: 14px;
}

.section-content.content-padding .content .right .description p {
  margin-bottom: 10px;
}

@media (min-width: 576px) {
  .section-content.content-padding .content .right .title {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 23px;
  }

  .section-content.content-padding .content .right .description {
    font-size: 16px;
    line-height: 24px;
  }

  .section-content.content-padding .content .right .description p {
    margin-bottom: 23px;
  }
}

.section-content.content-padding .content .button-row {
  padding-top: 30px;
}

.section-content.content-padding .content .button-row a {
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.section-content.content-padding .content .button-row a p {
  text-transform: uppercase;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.09em;
  color: #59a6de;
  padding-right: 14px;
  transition: color 0.2s linear;
  transition: all 0.2s ease-in-out;
}

.section-content.content-padding .content .button-row a:hover {
  transform: translate(2px, -2px);
}

.section-content.content-padding .content .button-row a:hover p {
  text-shadow: -2px 2px 3px #59a6de;
}

.section-content.content-padding .content .button-row a:hover .icon {
  filter: drop-shadow(-2px 2px 2px #59a6de);
}

.section-content.content-padding .full-width {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-content.content-padding .full-width .card {
  position: relative;
  width: 100%;
  height: 350px;
  max-height: 80vh;
  background-image: url("./../assets/img/rolls-industrial-cotton-fabric.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px;
  background-position-x: -60px;
}

@media (min-width: 576px) {
  .section-content.content-padding .full-width .card {
    height: 446px;
  }
}

@media (min-width: 992px) {
  .section-content.content-padding .full-width .card {
    background-position-x: 0;
  }
}

@media (min-width: 1440px) {
  .section-content.content-padding .full-width .card {
    max-height: 70vh;
  }
}

.section-content.content-padding .full-width .card .card-content,
.section-content.content-padding .full-width .card .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.section-content.content-padding .full-width .card .overlay {
  z-index: 0;
  background-image: url("./../assets/img/overlay.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 28px;
}

.section-content.content-padding .full-width .card .card-content {
  z-index: 1;
}

.section-content.content-padding .full-width .card .title {
  font-size: 35px;
  line-height: 49px;
  padding: 31px 0 0 46px;
  width: 100%;
}

.section-content.content-padding .full-width .card .title.-top {
  position: absolute;
  left: 26px;
  top: 15px;
  padding: 0;
}

@media (min-width: 992px) {
  .section-content.content-padding .full-width .card .title {
    font-size: 50px;
    line-height: 64px;
  }

  .section-content.content-padding .full-width .card .title.-top {
    left: 46px;
    top: 31px;
  }
}

@media (min-width: 1440px) {
  .section-content.content-padding .full-width .card .title {
    font-size: 70px;
    line-height: 84px;
  }
}

.section-content.content-padding .full-width .card .bottom.-bottom {
  width: 90%;
  position: absolute;
  left: 26px;
  bottom: 15px;
  padding: 0;
}

.section-content.content-padding .full-width .card .bottom.-bottom .des-title,
.section-content.content-padding .full-width .card .bottom.-bottom .description {
  padding-left: 0;
}

.section-content.content-padding .full-width .card .bottom.-bottom .description {
  padding-bottom: 10px;
}

@media (min-width: 992px) {
  .section-content.content-padding .full-width .card .bottom.-bottom {
    left: 46px;
    bottom: 31px;
  }
}

.section-content.content-padding .full-width .card .card-content.relative {
  position: relative;
}

.section-content.content-padding .full-width .card .card-content.flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.section-content.content-padding .full-width .card .des-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.09em;
  padding: 0 0 21px 46px;
}

.section-content.content-padding .full-width .card .description {
  font-size: 16px;
  line-height: 24px;
  width: 472px;
  max-width: 90%;
  padding: 0 0 31px 46px;
}

@media (min-width: 992px) {
  .section-content.content-padding .full-width .card .description {
    font-size: 18px;
  }
}

.section-content.content-padding .full-width .card .navigation {
  position: absolute;
  top: calc(100% + 30px);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
}

.section-content.content-padding .full-width .card .navigation .circle {
  cursor: pointer;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin: 0 5px;
  border: 1px solid #ffffff;
  background: transparent;
  transition: background 0.2s ease-in-out;
  opacity: 0.7;
}

.section-content.content-padding .full-width .card .navigation .circle.-active {
  background: #ffffff;
}

.section-content .certificates,
.section-content .products {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.section-content .certificates .-bg,
.section-content .products .-bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.section-content .products {
  display: flex;
  justify-content: center;
}

.section-content .products .-bg .background-img {
  width: 100%;
  height: 257px;
  background-image: url("./../assets/img/line.png");
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.2s linear;
}

@media (min-width: 576px) {
  .section-content .products .-bg .background-img {
    height: 337px;
  }
}

@media (min-width: 768px) {
  .section-content .products .-bg .background-img {
    height: 437px;
  }
}

@media (min-width: 992px) {
  .section-content .products .-bg .background-img {
    height: 537px;
  }
}

@media (min-width: 1250px) {
  .section-content .products .-bg .background-img {
    height: 607px;
  }
}

@media (min-width: 1440px) {
  .section-content .products .-bg .background-img {
    height: 808px;
  }
}

.section-content .products .product-list {
  max-width: 1154px;
  width: calc(100% - 70px);
  height: 480px;
  padding: 0 60px 0 50px;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 0 -15px;
}

@media (min-width: 768px) {
  .section-content .products .product-list {
    padding: 0 80px;
  }
}

@media (min-width: 992px) {
  .section-content .products .product-list {
    padding: 0 80px;
    margin: 0 -20px;
  }
}

@media (min-width: 1250px) {
  .section-content .products .product-list {
    padding: 0 165px;
    margin: 0 -31px;
  }
}

.section-content .products .product-list .product-list-inner {
  width: 100%;
  height: 100%;
}

.section-content .products .product-list .product-list-inner .slick-list {
  height: 100%;
}

.section-content .products .product-list .product-list-inner .slick-list .slick-track {
  height: 100%;
}

.section-content .products .product-list .product-list-inner .slick-slide {
  padding: 30px 12px 30px 12px !important;
}

@media screen and (min-width: 992px) {
  .section-content .products .product-list .product-list-inner .slick-slide {
    padding: 30px 22px 60px 22px !important;
  }
}

.section-content .products .product-list .product-card {
  width: calc(100% / 3);
  height: calc(100% / 3);
  border-radius: 20px;
  position: relative;
}

.section-content .products .product-list .product-card img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  margin-bottom: 10px;
  transition: transform 0.2s ease-in-out;
}

@media (min-width: 768px) {
  .section-content .products .product-list .product-card img {
    margin-bottom: 20px;
  }
}

.section-content .products .product-list .product-card .title {
  font-size: 24px;
  line-height: 29px;
  text-shadow: 0px 4px 66px rgba(0, 0, 0, 0.12);
  transition: color 0.2s ease-in-out;
}

@media (min-width: 992px) {
  .section-content .products .product-list .product-card .title {
    font-size: 35px;
    line-height: 40px;
    right: -18px;
  }
}

@media (min-width: 1440px) {
  .section-content .products .product-list .product-card .title {
    font-size: 50px;
    line-height: 55px;
    right: -23px;
  }
}

.section-content .products .product-list .product-card.move-bottom {
  transform: translateY(0);
}

@media (min-width: 576px) {
  .section-content .products .product-list .product-card.move-bottom {
    transform: translateY(20px);
  }
}

@media (min-width: 992px) {
  .section-content .products .product-list .product-card.move-bottom {
    transform: translateY(40px);
  }
}

.section-content .products .product-list .product-card .title-cont {
  transition: all 0.2s ease-in-out;
}

.section-content .products .product-list .product-card .space-for-hover {
  width: 100%;
  height: 70px;
}

@media (min-width: 576px) {
  .section-content .products .product-list .product-card .space-for-hover {
    height: 30px;
  }
}

.section-content .products .product-list .product-card:hover img {
  transform: scale(1.08);
}

@media (min-width: 992px) {
  .section-content .products .product-list .product-card:hover img {
    transform: scale(1.12);
  }
}

.section-content .products .product-list .product-card:hover .title-cont {
  transform: translateY(10px);
}

@media (min-width: 768px) {
  .section-content .products .product-list .product-card:hover .title-cont {
    transform: translateY(15px);
  }
}

@media (min-width: 992px) {
  .section-content .products .product-list .product-card:hover .title-cont {
    transform: translateY(25px);
  }
}

.section-content .products .product-list .product-card:hover .title-cont .title {
  color: #59a6de;
}

.section-content .products .product-list .section-title {
  position: absolute;
  left: 15px;
  top: 30px;
  font-size: 28px;
  line-height: 42px;
  text-align: right;
}

@media (min-width: 768px) {
  .section-content .products .product-list .section-title {
    left: 35px;
    top: 30px;
    font-size: 35px;
    line-height: 49px;
  }
}

@media (min-width: 992px) {
  .section-content .products .product-list .section-title {
    left: 30px;
    top: 30px;
    font-size: 50px;
    line-height: 64px;
  }
}

@media (min-width: 1250px) {
  .section-content .products .product-list .section-title {
    font-size: 70px;
    line-height: 84px;
    left: 80px;
    top: 30px;
  }
}

.section-content .certificates .-bg .background-img {
  width: 100%;
  height: 300px;
  background-image: url("./../assets/img/line-2.png");
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 576px) {
  .section-content .certificates .-bg .background-img {
    height: 460px;
  }
}

@media (min-width: 992px) {
  .section-content .certificates .-bg .background-img {
    height: 660px;
  }
}

.section-content .certificates .certificate-list {
  max-width: 1110px;
  margin: 0 auto;
  position: relative;
  padding: 30px 50px 0 20px;
  width: calc(100% - 50px - 20px);
}

@media (min-width: 768px) {
  .section-content .certificates .certificate-list {
    padding: 50px 50px 0 50px;
    width: calc(100% - 50px - 50px);
  }
}

@media (min-width: 1250px) {
  .section-content .certificates .certificate-list {
    padding: 70px 145px 0 145px;
    width: calc(100% - 145px - 145px);
  }
}

.section-content .certificates .certificate-list .home-slaider {
  width: 100%;
}

.section-content .certificates .certificate-list .section-title {
  position: absolute;
  top: -44px;
  right: 0px;
  font-size: 35px;
  line-height: 49px;
  padding-right: 60px;
}

@media (min-width: 768px) {
  .section-content .certificates .certificate-list .section-title {
    font-size: 50px;
    line-height: 64px;
    padding-right: 70px;
    top: -50px;
    right: 10px;
    right: 10px;
    right: 0px;
  }
}

@media (min-width: 1250px) {
  .section-content .certificates .certificate-list .section-title {
    font-size: 70px;
    line-height: 84px;
    padding-right: 145px;
  }
}

.section-content .certificates .certificate-list .cer {
  position: absolute;
  z-index: 99;
  top: calc(50% - 36px);
  cursor: pointer;
}

.section-content .certificates .certificate-list .cer.-arrow-left {
  left: calc(20px - 10px);
}

.section-content .certificates .certificate-list .cer.-arrow-right {
  top: calc(50% + 13px);
  right: 38px;
}

@media (min-width: 768px) {
  .section-content .certificates .certificate-list .cer {
    top: calc(50% - 30px);
  }

  .section-content .certificates .certificate-list .cer.-arrow-left {
    left: 38px;
  }

  .section-content .certificates .certificate-list .cer.-arrow-right {
    top: calc(50% + 13px);
    right: 38px;
  }
}

@media (min-width: 1250px) {
  .section-content .certificates .certificate-list .cer {
    top: calc(50% - 36px);
  }

  .section-content .certificates .certificate-list .cer.-arrow-left {
    left: calc(145px - 20px);
  }

  .section-content .certificates .certificate-list .cer.-arrow-right {
    top: calc(50% + 13px);
    right: calc(145px - 20px);
  }
}

@media only screen and (hover: none) and (pointer: coarse) {
  .section-content .certificates .certificate-list .cer.-arrow-left {
    display: none;
  }

  .section-content .certificates .certificate-list .cer.-arrow-right {
    display: none;
  }
}

.section-content .certificates .certificate-card {
  width: calc(253px * 1.3);
}

.section-content .certificates .certificate-card.move-bottom {
  padding-top: 40px;
}

.section-content .certificates .certificate-card.move-top {
  padding-bottom: 40px;
}

.section-content .certificates .certificate-card img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.section-content .certificates .certificate-card .title {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  margin-top: 10px;
}

.section-content.footer-content {
  position: relative;
  display: flex;
  align-items: center;
}

.section-content.footer-content .footer.-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 20px - 20px);
  padding: 0 20px 36px 20px;
}

@media (min-width: 768px) {
  .section-content.footer-content .footer.-bottom {
    width: calc( 100% - 80px - 80px );
    padding: 0 80px 36px 80px;
  }
}

@media (min-width: 1250px) {
  .section-content.footer-content .footer.-bottom {
    width: calc( 100% - 165px - 165px );
    padding: 0 165px 36px 165px;
  }
}

.section-content.footer-content .footer.-bottom .footer-content {
  max-width: 1110px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.section-content.footer-content .footer.-bottom .footer-content > a {
  transition: color 0.2s ease-in-out;
  margin: 0 auto;
  padding: 8px 0;
}

.section-content.footer-content .footer.-bottom .footer-content > a:hover {
  color: #59a6de;
}

@media (min-width: 576px) {
  .section-content.footer-content .footer.-bottom .footer-content > a {
    padding: 0;
  }
}

.section-content.footer-content .footer.-bottom p {
  font-size: 16px;
  line-height: 24px;
  width: 100%;
}

@media (min-width: 576px) {
  .section-content.footer-content .footer.-bottom p {
    width: auto;
  }
}

@media screen and (max-width: 575px) {
  .section-content.footer-content .footer.-bottom p {
    text-align: center;
  }
}

.section-content.footer-content .footer.-bottom p a {
  display: inline-block;
  margin-left: 10px;
}

.section-content.footer-content .footer.-top {
  width: 100%;
  position: relative;
  margin-bottom: 200px;
}

@media (min-width: 576px) {
  .section-content.footer-content .footer.-top {
    margin-bottom: 0px;
  }
}

.section-content.footer-content .footer.-top .content {
  width: calc(100% - 20px - 20px);
  padding: 0 20px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 575px) {
  .section-content.footer-content .footer.-top .content {
    flex-wrap: wrap;
    max-height: 200px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (min-width: 768px) {
  .section-content.footer-content .footer.-top .content {
    width: calc( 100% - 80px - 80px );
    padding: 0 80px;
  }
}

@media (min-width: 1250px) {
  .section-content.footer-content .footer.-top .content {
    width: calc( 100% - 165px - 165px );
    padding: 0 165px;
  }
}

.section-content.footer-content .footer.-top .content .left,
.section-content.footer-content .footer.-top .content .right {
  width: 100%;
}

@media (min-width: 576px) {
  .section-content.footer-content .footer.-top .content .left,
  .section-content.footer-content .footer.-top .content .right {
    width: 50%;
  }
}

.section-content.footer-content .footer.-top .content .left .first {
  font-size: 25px;
  line-height: 35px;
  text-align: center;
}

@media (min-width: 576px) {
  .section-content.footer-content .footer.-top .content .left .first {
    text-align: left;
  }
}

.section-content.footer-content .footer.-top .content .left .second {
  font-size: 35px;
  line-height: 49px;
  text-align: center;
}

@media (min-width: 576px) {
  .section-content.footer-content .footer.-top .content .left .second {
    text-align: left;
  }
}

@media (min-width: 768px) {
  .section-content.footer-content .footer.-top .content .left .first {
    font-size: 30px;
    line-height: 40px;
  }

  .section-content.footer-content .footer.-top .content .left .second {
    font-size: 50px;
    line-height: 64px;
  }
}

@media (min-width: 1250px) {
  .section-content.footer-content .footer.-top .content .left .first {
    font-size: 50px;
    line-height: 60px;
  }

  .section-content.footer-content .footer.-top .content .left .second {
    font-size: 70px;
    line-height: 84px;
  }
}

.section-content.footer-content .footer.-top .content .right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (max-width: 575px) {
  .section-content.footer-content .footer.-top .content .right {
    justify-content: center;
  }
}

.section-content.footer-content .footer.-top .content .right p {
  font-size: 25px;
  line-height: 35px;
  padding-right: 12px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .section-content.footer-content .footer.-top .content .right p {
    font-size: 30px;
    line-height: 40px;
  }
}

@media (min-width: 1250px) {
  .section-content.footer-content .footer.-top .content .right p {
    font-size: 50px;
    line-height: 60px;
  }
}

.section-content.footer-content .footer.-top .content .right .icon {
  cursor: pointer;
}

.section-content.footer-content .footer.-bg {
  background-image: url("./../assets/img/image-3.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  width: 100%;
  background-position: center;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media (min-width: 576px) {
  .section-content.footer-content .footer.-bg {
    height: 308px;
  }
}

@media (min-width: 768px) {
  .section-content.footer-content .footer.-bg {
    height: 408px;
  }
}

@media (min-width: 1250px) {
  .section-content.footer-content .footer.-bg {
    height: 508px;
  }
}

@media (min-width: 1440px) {
  .section-content.footer-content .footer.-bg {
    height: 608px;
  }
}

.products .-bg .background-img {
  width: 100%;
  height: 808px;
  background-image: url("./../assets/img/line.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.products .product-list {
  width: 100%;
  padding: 0 165px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.products .product-list .product-card {
  width: calc(328px * 1.4);
  height: calc(415px * 1.4);
  border-radius: 20px;
  position: relative;
}

.products .product-list .product-card img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.products .product-list .product-card .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.products .product-list .product-card .overlay.-blue {
  border-radius: 20px;
}

.products .product-list .product-card .overlay.-green {
  border-radius: 20px;
}

.products .product-list .product-card .title {
  font-size: 50px;
  line-height: 55px;
  text-shadow: 0px 4px 66px rgba(0, 0, 0, 0.12);
  text-align: center;
}

.products .product-list .product-card.move-bottom {
  transform: translateY(40px);
}

.products .product-list .section-title {
  position: absolute;
  left: -20px;
  top: 30px;
  font-size: 70px;
  line-height: 84px;
  text-align: right;
  transform: rotate(-180deg);
  -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
}

.pg-wrapper.pg-vertical {
  overflow: hidden !important;
}

@media screen and (max-height: 680px) {
  .section-content.content-padding .content .right .description p:last-child {
    display: none;
  }
}

html {
  overflow-x: hidden;
}

.page.-header {
  position: relative;
}

.page.-header .bg {
  width: 100%;
}

.page.-header .bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  min-height: 300px;
  -o-object-position: center bottom;
     object-position: center bottom;
  max-height: 95vh;
}

.page.-header .absolute-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.page.-header .head-content {
  height: calc(100% - 62px);
}

.page.-header .head-content.-contacts-us {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page.-header .columns-2 {
  max-width: 1110px;
  margin: 0 auto;
  width: calc(100% - (20px * 2));
  padding: 0 20px;
  height: 100%;
  display: block;
}

@media (min-width: 768px) {
  .page.-header .columns-2 {
    width: calc(100% - (80px * 2));
    padding: 0 80px;
  }
}

@media (min-width: 992px) {
  .page.-header .columns-2 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 60%;
  }
}

@media (min-width: 1250px) {
  .page.-header .columns-2 {
    width: calc(100% - (165px * 2));
    padding: 0 165px;
  }
}

.page.-header .columns-2 .col-left,
.page.-header .columns-2 .col-right {
  width: 100%;
}

@media (min-width: 992px) {
  .page.-header .columns-2 .col-left,
  .page.-header .columns-2 .col-right {
    width: 50%;
  }
}

.page.-header .columns-2 .col-left .title {
  font-size: 32px;
  line-height: 46px;
  max-width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .page.-header .columns-2 .col-left .title {
    font-size: 50px;
    line-height: 64px;
  }
}

@media (min-width: 992px) {
  .page.-header .columns-2 .col-left .title {
    max-width: 478px;
    margin-bottom: 0;
    margin-top: 0;
  }
}

@media (min-width: 1250px) {
  .page.-header .columns-2 .col-left .title {
    font-size: 70px;
    line-height: 84px;
  }
}

.page.-header .columns-2 .col-right {
  padding-top: 15px;
  display: none;
}

.page.-header .columns-2 .col-right .description {
  font-size: 16px;
  line-height: 24px;
}

@media (min-width: 768px) {
  .page.-header .columns-2 .col-right {
    display: block;
  }
}

.page.-header .columns-2 .col-right .tabs ul {
  padding-bottom: 25px;
}

@media (min-width: 992px) {
  .page.-header .columns-2 .col-right .tabs ul {
    padding-bottom: 30px;
  }
}

.page.-header .columns-2 .col-right .tabs ul li {
  display: inline-block;
  margin-right: 31px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}

.page.-header .columns-2 .col-right .tabs ul li:last-child {
  margin-right: 0;
}

.page.-header .columns-2 .col-right .tabs ul li.active {
  color: #59a6de;
  position: relative;
}

.page.-header .columns-2 .col-right .tabs ul li.active::after {
  content: "";
  position: absolute;
  bottom: -7px;
  left: 0;
  width: 100%;
  border-bottom: 3px solid #59a6de;
}

.page.-header .columns-1 {
  padding: 0 20px;
  padding-bottom: 120px;
  padding-right: 40px;
  width: 100%;
  z-index: -1;
  max-width: 1110px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .page.-header .columns-1 {
    padding: 0 80px;
    padding-bottom: 300px;
  }
}

@media (min-width: 1250px) {
  .page.-header .columns-1 {
    padding: 0 165px;
    padding-bottom: 300px;
  }
}

.page.-header .columns-1 .title {
  font-size: 33px;
  line-height: 49px;
  text-align: center;
}

@media (min-width: 768px) {
  .page.-header .columns-1 .title {
    font-size: 50px;
    line-height: 64px;
  }
}

@media (min-width: 1250px) {
  .page.-header .columns-1 .title {
    line-height: 84px;
    font-size: 70px;
  }
}

.page.-header .columns-1 .title-right {
  font-size: 18px;
  line-height: 25px;
  text-align: right;
  width: 100%;
  float: right;
}

.page.-header .columns-1 .title-right.-fs-small {
  padding-top: 60px;
  font-size: 20px;
}

@media (min-width: 576px) {
  .page.-header .columns-1 .title-right {
    width: 400px;
  }
}

@media (min-width: 768px) {
  .page.-header .columns-1 .title-right {
    font-size: 30px;
    line-height: 40px;
    width: 520px;
  }

  .page.-header .columns-1 .title-right.-fs-small {
    font-size: 25px;
  }
}

@media (min-width: 1250px) {
  .page.-header .columns-1 .title-right {
    font-size: 50px;
    line-height: 60px;
    width: 800px;
  }

  .page.-header .columns-1 .title-right.-fs-small {
    font-size: 40px;
  }
}

.page.-footer {
  max-width: 1440px;
  margin: 0 auto;
}

.page.-footer .footer-bottom {
  height: 70px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  padding-bottom: 20px;
}

@media (min-width: 576px) {
  .page.-footer .footer-bottom {
    height: 106px;
    padding-bottom: 0px;
  }
}

@media (min-width: 768px) {
  .page.-footer .footer-bottom {
    padding: 0 80px;
  }
}

@media (min-width: 1250px) {
  .page.-footer .footer-bottom {
    padding: 0 165px;
  }
}

.page.-footer .footer-bottom > a {
  transition: color 0.2s ease-in-out;
}

@media screen and (max-width: 349px) {
  .page.-footer .footer-bottom > a {
    margin: 0 auto;
    padding: 8px 0;
  }
}

.page.-footer .footer-bottom > a:hover {
  color: #59a6de;
}

.page.-footer .footer-bottom p {
  font-size: 16px;
  line-height: 24px;
}

.page.-footer .footer-bottom p a {
  display: block;
  margin-left: 10px;
}

.page.-footer .footer-bottom p:last-child {
  display: flex;
  align-items: baseline;
}

@media screen and (max-width: 350px) {
  .page.-footer .footer-bottom p {
    width: 100%;
    text-align: center;
  }
}

.page.-footer .footer-bottom p:last-child {
  width: 100%;
  justify-content: center;
}

@media (min-width: 576px) {
  .page.-footer .footer-bottom p:last-child {
    width: auto;
  }
}

.page .footer-top {
  position: relative;
  margin-bottom: 30px;
}

@media (min-width: 576px) {
  .page .footer-top {
    margin-bottom: 0;
  }
}

.page .footer-top .content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 20px;
  width: calc(100% - (20px * 2));
  height: 140px;
  padding-top: 30px;
  padding-bottom: 30px;
  max-width: 1110px;
  margin: 0 auto;
}

@media (min-width: 576px) {
  .page .footer-top .content {
    height: 408px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (min-width: 768px) {
  .page .footer-top .content {
    padding: 0 80px;
    width: calc(100% - (80px * 2));
  }
}

@media (min-width: 1250px) {
  .page .footer-top .content {
    height: 408px;
    padding: 0 165px;
    width: calc(100% - (165px * 2));
  }
}

@media (min-width: 1440px) {
  .page .footer-top .content {
    height: calc(408px * 1.35);
  }
}

.page .footer-top .content .col-left {
  width: 100%;
}

@media (min-width: 576px) {
  .page .footer-top .content .col-left {
    width: 70%;
  }
}

.page .footer-top .content .col-right {
  width: 100%;
}

@media (min-width: 576px) {
  .page .footer-top .content .col-right {
    width: 30%;
  }
}

.page .footer-top .content .col-left .question {
  font-size: 25px;
  line-height: 35px;
  text-align: center;
}

@media (min-width: 576px) {
  .page .footer-top .content .col-left .question {
    text-align: left;
  }
}

.page .footer-top .content .col-left .title {
  font-size: 32px;
  line-height: 46px;
  text-align: center;
}

@media (min-width: 576px) {
  .page .footer-top .content .col-left .title {
    text-align: left;
  }
}

@media (min-width: 768px) {
  .page .footer-top .content .col-left .question {
    font-size: 30px;
    line-height: 40px;
  }

  .page .footer-top .content .col-left .title {
    font-size: 50px;
    line-height: 64px;
  }
}

@media (min-width: 1250px) {
  .page .footer-top .content .col-left .question {
    font-size: 50px;
    line-height: 60px;
  }

  .page .footer-top .content .col-left .title {
    font-size: 70px;
    line-height: 84px;
  }
}

.page .footer-top .content .col-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 576px) {
  .page .footer-top .content .col-right {
    justify-content: flex-end;
  }
}

.page .footer-top .content .col-right a {
  font-size: 25px;
  line-height: 35px;
  text-decoration: none;
  color: #ffffff;
}

@media (min-width: 768px) {
  .page .footer-top .content .col-right a {
    font-size: 30px;
    line-height: 40px;
  }
}

@media (min-width: 1250px) {
  .page .footer-top .content .col-right a {
    font-size: 50px;
    line-height: 60px;
  }
}

.page .footer-top .content .col-right i {
  margin-left: 12px;
}

.page .footer-top .-bg {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background-image: url("./../assets/bg/normal-page-footer-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
}

.page.-page-content {
  max-width: 1100px;
  margin: 0 auto;
  width: calc(100% - (20px * 2));
  padding: 24px 20px 35px 20px;
}

@media (min-width: 768px) {
  .page.-page-content {
    width: calc(100% - (80px * 2));
    padding: 24px 80px 35px 80px;
  }
}

@media (min-width: 1250px) {
  .page.-page-content {
    width: calc(100% - (165px * 2));
    padding: 24px 165px 35px 165px;
  }
}

.privacy-policy h4 {
  font-weight: 600;
  font-size: 18px;
  margin-top: 30px;
}

#products {
  overflow-x: hidden;
}

#products .columns-2 .col-right {
  display: none;
}

@media (min-width: 992px) {
  #products .columns-2 .col-right {
    display: block;
  }
}

#products .page.-page-content a {
  box-sizing: border-box;
}

.product-row {
  padding-top: 100px;
  width: 100%;
  display: flex;
}

@media screen and (max-width: 767px) {
  .product-row {
    flex-wrap: wrap;
  }
}

@media (min-width: 768px) {
  .product-row {
    padding-top: 180px;
  }
}

@media (min-width: 992px) {
  .product-row {
    padding-top: 215px;
  }
}

.product-row.right-align,
.product-row.left-align {
  position: relative;
}

.product-row.right-align .prod-info,
.product-row.left-align .prod-info {
  width: 100%;
  order: 1;
}

@media (min-width: 768px) {
  .product-row.right-align .prod-info,
  .product-row.left-align .prod-info {
    width: 42%;
    order: 2;
  }
}

.product-row.right-align .title,
.product-row.left-align .title {
  font-size: 32px;
  line-height: 49px;
  width: 100%;
  margin-bottom: 20px;
}

.product-row.right-align .title.-active,
.product-row.left-align .title.-active {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

@media (min-width: 768px) {
  .product-row.right-align .title,
  .product-row.left-align .title {
    font-size: 50px;
    line-height: 64px;
    max-width: 268px;
    margin-bottom: 50px;
  }
}

@media (min-width: 1250px) {
  .product-row.right-align .title,
  .product-row.left-align .title {
    font-size: 70px;
    line-height: 84px;
    max-width: 332px;
  }
}

.product-row.right-align .description,
.product-row.left-align .description {
  box-sizing: border-box;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  display: none;
}

@media (min-width: 768px) {
  .product-row.right-align .description,
  .product-row.left-align .description {
    font-size: 16px;
    line-height: 22px;
    display: block;
  }
}

@media (min-width: 992px) {
  .product-row.right-align .description,
  .product-row.left-align .description {
    line-height: 24px;
  }
}

.product-row.right-align .bg,
.product-row.left-align .bg {
  width: 100%;
  max-height: 507px;
  border-radius: 20px;
  position: relative;
  order: 2;
}

@media (min-width: 768px) {
  .product-row.right-align .bg,
  .product-row.left-align .bg {
    width: 58%;
    order: 1;
  }
}

.product-row.right-align .bg .shadow,
.product-row.left-align .bg .shadow {
  border-radius: 20px;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.product-row.right-align .bg .shadow.-active,
.product-row.left-align .bg .shadow.-active {
  opacity: 1;
}

.product-row.right-align .bg img,
.product-row.left-align .bg img {
  border-radius: 20px;
  -o-object-position: center center;
     object-position: center center;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: calc(100% + 50px);
}

.product-row.left-align .bg .shadow {
  top: -12px;
  left: 7px;
  background: linear-gradient(266.24deg, rgba(20, 156, 255, 0) 0%, rgba(41, 22, 255, 0.42) 96.11%);
}

@media (min-width: 768px) {
  .product-row.left-align .bg .shadow {
    top: -44px;
    left: 57px;
  }
}

.product-row.left-align .title {
  padding-right: 40px;
}

.product-row.left-align .description {
  padding-right: 40px;
}

@media (min-width: 768px) {
  .product-row.left-align .title {
    padding-right: 0;
    padding-left: 40px;
  }

  .product-row.left-align .description {
    padding-right: 0;
    padding-left: 40px;
  }
}

@media (min-width: 1250px) {
  .product-row.left-align .title {
    padding-left: 80px;
  }

  .product-row.left-align .description {
    padding-left: 80px;
  }
}

.product-row.right-align .bg {
  order: 2;
}

.product-row.right-align .bg .shadow {
  top: -12px;
  right: 7px;
  background: linear-gradient(90.24deg, rgba(20, 156, 255, 0) 0%, rgba(41, 22, 255, 0.42) 96.11%);
}

@media (min-width: 768px) {
  .product-row.right-align .bg .shadow {
    top: -44px;
    right: 57px;
  }
}

.product-row.right-align .prod-info {
  order: 1;
}

.product-row.right-align .title {
  padding-right: 40px;
}

.product-row.right-align .description {
  padding-right: 40px;
}

@media (min-width: 1250px) {
  .product-row.right-align .title {
    padding-right: 80px;
  }

  .product-row.right-align .description {
    padding-right: 80px;
  }
}

@media screen and (max-width: 767px) {
  #products .page.-page-content a:first-child .product-row {
    padding-top: 0;
  }

  #products .page.-page-content a:first-child .product-row .title {
    max-width: 100%;
  }

  #products .page.-page-content a:last-child .product-row {
    padding-bottom: 80px;
  }
}

.contacts-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /*the container must be positioned relative:*/
}

.contacts-row.-first {
  padding-top: 43px;
  flex-wrap: wrap;
  max-width: 450px;
  margin: 0 auto;
}

@media (min-width: 992px) {
  .contacts-row.-first {
    flex-wrap: nowrap;
    max-width: 100%;
  }
}

.contacts-row .contact-form {
  width: 100%;
}

@media (min-width: 992px) {
  .contacts-row .contact-form {
    width: 48%;
  }
}

.contacts-row.-form {
  padding-top: 0px;
  padding-bottom: 40px;
  position: relative;
}

@media (min-width: 992px) {
  .contacts-row.-form {
    padding-top: 114px;
    padding-bottom: 114px;
  }
}

.contacts-row.-form .col {
  background: linear-gradient(266.24deg, rgba(20, 156, 255, 0) 0%, rgba(41, 22, 255, 0.42) 96.11%);
  width: 100%;
  border-radius: 40px;
  min-height: 383px;
  padding: 20px;
}

@media (min-width: 768px) {
  .contacts-row.-form .col {
    padding: 42px 53px;
  }
}

@media screen and (max-width: 991px) {
  .contacts-row.-form .col {
    padding-right: 20px;
    padding-bottom: 0;
  }
}

.contacts-row.-form .col .title {
  font-size: 25px;
  line-height: 35px;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .contacts-row.-form .col .title {
    font-size: 30px;
    line-height: 40px;
  }
}

@media (min-width: 1250px) {
  .contacts-row.-form .col .title {
    font-size: 50px;
    line-height: 60px;
  }
}

.contacts-row.-form .card {
  background: url("./../assets/bg/contact-right-card.svg");
  background-size: cover;
  background-position: 99% 0%;
  border-radius: 40px;
  width: calc(100% - 20px);
  position: relative;
  top: 20px;
  right: 0;
  padding: 20px 0 20px 20px;
  margin: 0 0 0 auto;
}

@media (min-width: 768px) {
  .contacts-row.-form .card {
    padding: 42px 0 42px 53px;
    width: calc(100% - 53px);
  }
}

@media (min-width: 992px) {
  .contacts-row.-form .card {
    position: absolute;
    top: calc(98px + 114px);
    width: calc(49% - 52px);
  }
}

.contacts-row.-form .card .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contacts-row.-form .card .row.-right {
  justify-content: flex-end;
}

.contacts-row.-form .card .row.-space-between {
  justify-content: space-between;
  align-items: baseline;
}

.contacts-row.-form .card .company-name {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.09em;
  padding-top: 41px;
  padding-bottom: 28px;
}

.contacts-row.-form .card .contact-info {
  font-size: 14px;
  line-height: 22px;
}

@media (min-width: 576px) {
  .contacts-row.-form .card .contact-info {
    font-size: 16px;
    line-height: 24px;
  }
}

.contacts-row.-form .card .phone {
  padding-top: 37px;
}

.contacts-row.-form .card .email {
  padding-top: 15px;
}

.contacts-row.-form .card .phone,
.contacts-row.-form .card .email {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 15px;
}

.contacts-row.-form .card .phone .icon,
.contacts-row.-form .card .email .icon {
  margin-right: 7px;
  line-height: 15px;
}

@media (min-width: 576px) {
  .contacts-row.-form .card .phone,
  .contacts-row.-form .card .email {
    font-size: 27px;
    line-height: 24px;
  }

  .contacts-row.-form .card .phone .icon,
  .contacts-row.-form .card .email .icon {
    margin-right: 13px;
    line-height: 24px;
  }
}

.contacts-row.-form .card .social {
  padding-top: 26px;
  display: flex;
}

.contacts-row.-form .card .social .icon.-facebook {
  width: 12px;
  height: 24px;
  margin-right: 16px;
  cursor: pointer;
}

.contacts-row.-form .card .social .icon.-linkedin {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

@media (min-width: 576px) {
  .contacts-row.-form .card .social .icon.-facebook {
    width: 16.64px;
    height: 33.28px;
    margin-right: 33px;
  }

  .contacts-row.-form .card .social .icon.-linkedin {
    width: 33.28px;
    height: 33.28px;
  }
}

.contacts-row .contact {
  width: calc(100% - 10px);
  padding-right: 10px;
  padding-bottom: 40px;
}

@media (min-width: 420px) {
  .contacts-row .contact {
    width: auto;
  }
}

@media (min-width: 992px) {
  .contacts-row .contact {
    width: auto;
    padding-bottom: 0px;
  }
}

.contacts-row .contact .job-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.09em;
  text-transform: uppercase;
}

.contacts-row .contact .full-name {
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
}

.contacts-row .contact .phone,
.contacts-row .contact .email {
  text-decoration: none;
  display: flex;
  align-items: center;
  outline: none;
}

.contacts-row .contact .phone .icon,
.contacts-row .contact .email .icon {
  margin-right: 9px;
}

.contacts-row .contact .phone {
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.contacts-row .contact .email {
  font-size: 16px;
  line-height: 24px;
  color: #59a6de;
}

.contacts-row input[type=text],
.contacts-row select,
.contacts-row textarea {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #bdc6f2;
  padding: 6px 0;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}

.contacts-row textarea,
.contacts-row select,
.contacts-row input[type=text],
.contacts-row .action-row {
  width: 100%;
}

@media (min-width: 992px) {
  .contacts-row textarea,
  .contacts-row select,
  .contacts-row input[type=text],
  .contacts-row .action-row {
    width: calc(100% - 30px);
  }
}

.contacts-row input[type=text] {
  margin-right: 0px;
  color: #ffffff;
  width: calc(100% - 15px);
}

@media (min-width: 992px) {
  .contacts-row input[type=text] {
    margin-right: 30px;
  }
}

.contacts-row .row .col-6:last-child {
  text-align: right;
}

.contacts-row input[type=text]::-moz-placeholder {
  color: #bdc6f2;
}

.contacts-row input[type=text]:-ms-input-placeholder {
  color: #bdc6f2;
}

.contacts-row input[type=text]::placeholder {
  color: #bdc6f2;
}

.contacts-row input[type=text]:valid,
.contacts-row input[type=text]:focus {
  border-bottom: 1px solid #ffffff;
}

.contacts-row .custom-select {
  position: relative;
  margin-right: 0px;
}

@media (min-width: 992px) {
  .contacts-row .custom-select {
    margin-right: 15px;
  }
}

.contacts-row .custom-select select {
  display: none;
  /*hide original SELECT element:*/
}

.contacts-row .select-selected:after {
  position: absolute;
  content: "";
  bottom: 16px;
  right: 0px;
  width: 9px;
  height: 9px;
  background: url("./../assets/icons/dropdown_arrow_down.svg");
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 0.1s ease-in-out;
}

.contacts-row .select-items div,
.contacts-row .select-selected {
  color: #bdc6f2;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.contacts-row .select-selected {
  border: 0;
  border-bottom: 1px solid #bdc6f2;
  padding: 6px 0;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
  color: #bdc6f2;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.contacts-row .select-items div {
  padding: 8px 16px;
  color: #bdc6f2;
}

.contacts-row .select-items {
  position: absolute;
  background-color: #01022a;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

.contacts-row .select-hide {
  display: none;
}

.contacts-row .select-items div:hover,
.contacts-row .same-as-selected {
  background-color: #000000;
}

.contacts-row textarea {
  color: #ffffff;
  margin-right: 0px;
  width: calc(100% - 15px);
}

.contacts-row textarea::-moz-placeholder {
  color: #bdc6f2;
}

.contacts-row textarea:-ms-input-placeholder {
  color: #bdc6f2;
}

.contacts-row textarea::placeholder {
  color: #bdc6f2;
}

.contacts-row textarea:valid,
.contacts-row textarea:focus {
  border-bottom: 1px solid #ffffff;
}

.contacts-row input[type=checkbox] {
  margin-right: 8px;
  width: 18px;
  height: 18px;
}

.contacts-row .checkbox-row span {
  font-size: 15px;
  line-height: 24px;
  vertical-align: bottom;
}

.contacts-row .checkbox-row input {
  cursor: pointer;
}

.contacts-row .send-button {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.09em;
  text-decoration: none;
  color: #59a6de;
  text-transform: uppercase;
  min-width: 90px;
  text-align: right;
  background: transparent;
  border: 0;
}

.contacts-row .send-button div {
  display: inline-block;
  margin-left: 0px;
}

@media (min-width: 576px) {
  .contacts-row .send-button div {
    margin-left: 14px;
  }
}

.contacts-row .find-us {
  font-weight: 600;
  font-size: 20px;
}

@media (min-width: 576px) {
  .contacts-row .find-us {
    font-size: 24px;
  }
}

.contacts-row .find-us a {
  display: flex;
  transition: color 0.1s ease-in-out;
}

.contacts-row .find-us a .icon {
  transition: filter 0.1s ease-in-out;
  margin-right: 5px;
}

@media (min-width: 576px) {
  .contacts-row .find-us a .icon {
    margin-right: 10px;
  }
}

.contacts-row .find-us a:hover {
  color: #59a6de;
}

.contacts-row .find-us a:hover .icon {
  filter: invert(45%) sepia(74%) saturate(692%) hue-rotate(177deg) brightness(90%) contrast(91%);
}

.contacts-row .find-us a:hover svg {
  fill: #59a6de;
}

.contacts-row .with-error {
  position: relative;
}

.contacts-row .with-error .error-message {
  color: #FF0000;
  font-size: 12px;
  position: absolute;
  left: 0px;
  top: 60%;
  text-align: left;
}

.contacts-row .with-error .error-message.-priv {
  top: 100%;
}

@media screen and (max-width: 480px) {
  .contacts-row .with-error .error-message {
    font-size: 8px;
  }
}

.contacts-row .with-error .error-message.-second {
  left: 15px;
}

@media (min-width: 992px) {
  .contacts-row .with-error .error-message.-second {
    left: 0px;
  }
}

.contacts-row .action-row.with-error .error-message {
  position: absolute;
  left: 0px;
  bottom: -15px;
}

.contacts-row .success-message {
  padding-top: 10px;
  color: #ffffff;
  font-size: 12px;
}

.contacts-row.-form {
  padding-top: 80px;
}

.contacts-row.-form .card {
  top: calc(98px + 90px);
}

@media screen and (max-width: 991px) {
  .contacts-row.-form .card {
    top: 50px;
  }
}

@media screen and (max-width: 767px) {
  .contacts-row.-form .card {
    top: 20px;
  }
}

.about-us {
  margin-top: 60px;
  margin-bottom: 0px;
}

.about-us .about {
  flex-wrap: wrap;
}

@media (min-width: 992px) {
  .about-us .about {
    flex-wrap: nowrap;
  }
}

.about-us .about .col-6 {
  width: 100%;
}

@media (min-width: 992px) {
  .about-us .about .col-6 {
    width: 50%;
  }
}

.about-us .about .left {
  padding-right: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

@media (min-width: 1250px) {
  .about-us .about .left {
    padding-right: 97px;
  }
}

.about-us .about .left .title {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  margin-bottom: 28px;
}

.about-us .about .left .sub-title {
  font-size: 27px;
  line-height: 33px;
  margin-bottom: 28px;
}

.about-us .about .left .description {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
}

.about-us .about .right {
  position: relative;
  min-height: 100px;
  margin: 0 auto;
  margin-top: 30px;
  transform: translateX(-50px);
  width: auto;
}

@media (min-width: 992px) {
  .about-us .about .right {
    margin-top: 0;
    min-height: 400px;
    margin: 0;
    transform: translateX(0px);
  }
}

.about-us .about .right .img-1 {
  width: 100%;
  height: auto;
  position: absolute;
  top: -45px;
  left: 80px;
  z-index: -1;
}

@media (min-width: 576px) {
  .about-us .about .right .img-1 {
    top: -55px;
  }
}

@media screen and (max-width: 470px) {
  .about-us .about .right .img-1 {
    left: 60px;
  }
}

@media screen and (max-width: 350px) {
  .about-us .about .right .img-1 {
    left: 40px;
  }
}

.about-us .about .right .img-2 {
  min-width: 100%;
  height: 100%;
  transform: translateY(30px);
  display: flex;
  align-items: flex-end;
}

@media screen and (max-width: 991px) {
  .about-us .about .right .img-1,
  .about-us .about .right .img-2 {
    max-width: 400px;
    min-width: 0;
  }
}

.about-us .about .right img {
  width: 100%;
  height: 100%;
  -o-object-position: bottom;
     object-position: bottom;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 2rem;
  -o-object-fit: cover;
     object-fit: cover;
}

.certificate {
  margin-bottom: 72px;
  width: 100%;
  position: relative;
  min-height: 0px;
}

.certificate .bg {
  background-image: url("./../assets/bg/line.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 587px;
  position: absolute;
  z-index: -1;
  top: 57px;
  left: 0;
}

@media (min-width: 992px) {
  .certificate {
    min-height: 670px;
  }

  .certificate .bg {
    height: 670px;
  }
}

@media (min-width: 1440px) {
  .certificate {
    min-height: 900px;
  }

  .certificate .bg {
    height: 900px;
  }
}

.certificate .content {
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
}

@media (min-width: 992px) {
  .certificate .content {
    justify-content: flex-start;
  }
}

.certificate .content .carusel {
  max-width: 450px;
  max-height: 450px;
  margin-right: 0px;
  order: 3;
  display: flex;
  justify-content: center;
}

.certificate .content .carusel img {
  border-radius: 10px;
}

.certificate .content .carusel .slick-dots {
  position: absolute;
  bottom: -40px;
  display: flex;
}

.certificate .content .carusel .slick-dots button {
  border-radius: 2rem;
  height: 10px;
  width: 10px;
  background: transparent;
  border: 1px solid #bdc6f2;
  padding: 0;
  color: transparent;
  margin: 0 5px;
}

.certificate .content .carusel .slick-dots .slick-active button {
  background: #bdc6f2;
}

.certificate .content .description {
  font-size: 16px;
  line-height: 24px;
  margin-right: 0px;
  order: 2;
  margin-bottom: 30px;
}

.certificate .content .title {
  order: 1;
  font-size: 31px;
  line-height: 31px;
  -ms-writing-mode: lr-tb;
      writing-mode: horizontal-tb;
  margin-bottom: 20px;
  margin-top: 30px;
  text-align: left;
  padding-top: 30px;
}

.certificate .content .title br {
  display: none;
}

@media (min-width: 576px) {
  .certificate .content .title {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .certificate .content .title {
    margin-bottom: 30px;
    padding-top: 0;
  }
}

.certificate .content .carusel {
  width: 88vw;
  margin: 0 auto;
}

.certificate .content .description,
.certificate .content .title {
  width: 100%;
}

.certificate .content .description,
.certificate .content .title {
  padding-right: 20px;
}

@media (min-width: 992px) {
  .certificate .content {
    flex-wrap: nowrap;
    align-items: center;
  }

  .certificate .content .carusel,
  .certificate .content .description,
  .certificate .content .title {
    width: auto;
  }

  .certificate .content .carusel {
    order: 1;
    margin-right: 28px;
  }

  .certificate .content .description {
    order: 2;
    margin-right: 28px;
    margin-bottom: 0;
  }

  .certificate .content .title {
    order: 3;
    -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
    font-size: 50px;
    line-height: 50px;
    text-align: left;
    margin-bottom: 0;
  }

  .certificate .content .title br {
    display: block;
  }
}

@media (min-width: 1250px) {
  .certificate .content .title {
    font-size: 70px;
    line-height: 70px;
  }
}

.map-section {
  padding: 0 20px;
  margin-bottom: -50px;
  max-width: 1168px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .map-section {
    padding: 31px 80px 0 50px;
  }
}

@media (min-width: 1250px) {
  .map-section {
    padding: 71px 107px 0 165px;
  }
}

.map-section .title {
  font-size: 32px;
  line-height: 46px;
  text-align: center;
}

@media (min-width: 768px) {
  .map-section .title {
    font-size: 50px;
    line-height: 64px;
  }
}

@media (min-width: 1250px) {
  .map-section .title {
    font-size: 70px;
    line-height: 84px;
  }
}

.map-section .map {
  display: flex;
  align-items: top;
  justify-content: center;
  padding-top: 90px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 30px;
}

@media (min-width: 576px) {
  .map-section .map {
    margin-bottom: 0px;
  }
}

@media (min-width: 992px) {
  .map-section .map {
    margin-top: 0;
    padding-top: 40px;
    align-items: center;
    justify-content: space-between;
  }
}

.map-section .map .map-info {
  padding-top: 20px;
  position: absolute;
  display: flex;
  top: 0px;
}

@media (min-width: 992px) {
  .map-section .map .map-info {
    top: unset;
    display: block;
  }
}

.map-section .map .map-info .info {
  margin-bottom: 46px;
  margin-left: 15px;
  margin-right: 15px;
}

@media (min-width: 992px) {
  .map-section .map .map-info .info {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.map-section .map .map-info .info .percentage {
  font-size: 25px;
  line-height: 35px;
  text-align: center;
  transition: color 0.4s linear;
}

@media (min-width: 768px) {
  .map-section .map .map-info .info .percentage {
    font-size: 35px;
    line-height: 45px;
  }
}

@media (min-width: 992px) {
  .map-section .map .map-info .info .percentage {
    text-align: left;
  }
}

@media (min-width: 1250px) {
  .map-section .map .map-info .info .percentage {
    font-size: 50px;
    line-height: 60px;
  }
}

.map-section .map .map-info .info .title {
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  text-align: center;
  transition: color 0.4s linear;
}

@media (min-width: 768px) {
  .map-section .map .map-info .info .title {
    font-size: 14px;
    line-height: 17px;
  }
}

@media (min-width: 992px) {
  .map-section .map .map-info .info .title {
    text-align: left;
  }
}

@media (min-width: 1250px) {
  .map-section .map .map-info .info .title {
    font-size: 16px;
    line-height: 19px;
  }
}

.map-section .map .map-info .info.active .percentage,
.map-section .map .map-info .info.active .title {
  color: #59a6de;
}

.map-section .map .world-map {
  margin-top: 0px;
  margin-left: 0px;
  width: 100%;
}

@media (min-width: 992px) {
  .map-section .map .world-map {
    margin-left: 50px;
  }
}

.map-section .map .world-map svg {
  width: 100%;
  height: auto;
}

.map-section .map .world-map svg path {
  transition: fill-opacity 0.4s linear, stroke-opacity 0.4s linear;
}

@media screen and (max-width: 991px) {
  #aboutUsImg {
    margin-bottom: 50px;
    margin-top: 50px;
  }
}

@media screen and (max-width: 590px) {
  #aboutUsImg {
    margin-left: 50px;
  }
}

#product-view .columns-2 .col-right {
  display: none;
}

@media (min-width: 992px) {
  #product-view .columns-2 .col-right {
    display: block;
  }
}

#product-view .mobile-description {
  display: block;
  padding-right: 20px;
}

@media (min-width: 992px) {
  #product-view .mobile-description {
    display: none;
  }
}

.about-prod {
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
}

@media (min-width: 992px) {
  .about-prod {
    margin-top: 124px;
  }
}

.about-prod .left {
  width: 100%;
  margin-right: 0px;
  position: relative;
  margin-bottom: 40px;
}

@media (min-width: 576px) {
  .about-prod .left {
    width: 50%;
    margin-bottom: 0;
  }
}

@media (min-width: 1250px) {
  .about-prod .left {
    width: calc(50% - 65px);
    margin-right: 65px;
  }
}

.about-prod .left .bg {
  background: linear-gradient(266.24deg, rgba(20, 156, 255, 0) 0%, rgba(41, 22, 255, 0.42) 96.11%);
  border-radius: 20px;
  position: absolute;
  right: 0;
  top: -24px;
  z-index: -1;
  height: 60px;
  width: calc(100% - 27px);
}

@media (min-width: 420px) {
  .about-prod .left .bg {
    width: calc(100% - 37px);
    top: -24px;
  }
}

@media (min-width: 768px) {
  .about-prod .left .bg {
    width: calc(100% - 57px);
  }
}

.about-prod .left img {
  position: relative;
  border-radius: 20px;
  width: 100%;
  max-height: 507px;
  max-width: 507px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.about-prod .left .img-list-container {
  margin-top: 10px;
  width: calc(100% + 10px);
  margin-left: -5px;
  margin-right: -5px;
  position: relative;
}

.about-prod .left .img-list-container #singleProdImgPrev {
  background-image: url("./../assets/icons/arrow-left.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: -15px;
  z-index: 99;
  transform: translateY(-40%);
  cursor: pointer;
}

.about-prod .left .img-list-container #singleProdImgNext {
  background-image: url("./../assets/icons/arrow-right.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  right: -15px;
  z-index: 99;
  transform: translateY(-40%);
  cursor: pointer;
}

.about-prod .left #mainImage {
  cursor: zoom-in;
}

.about-prod .left #imgList .slick-slide {
  padding: 0 5px;
}

.about-prod .left #imgList .slick-slide img {
  height: 100px;
  width: 100px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  cursor: pointer;
}

.about-prod .left #imgList .slick-track {
  margin: 0 auto 0 0;
}

.about-prod .right {
  width: 100%;
}

@media (min-width: 576px) {
  .about-prod .right {
    width: 50%;
  }
}

.about-prod .right .info {
  padding-left: 0px;
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .about-prod .right .info {
    padding-left: 32px;
    margin-bottom: 20px;
  }
}

@media (min-width: 1250px) {
  .about-prod .right .info {
    padding-left: 65px;
    margin-bottom: 40px;
  }
}

.about-prod .right .info .title {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.09em;
  text-transform: uppercase;
}

.about-prod .right .info .sub-title {
  font-size: 20px;
  line-height: 28px;
}

@media (min-width: 768px) {
  .about-prod .right .info .sub-title {
    font-size: 30px;
    line-height: 38px;
  }
}

@media (min-width: 1250px) {
  .about-prod .right .info .sub-title {
    font-size: 40px;
    line-height: 48px;
  }
}

.other-prod-info {
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  min-height: 100px;
}

@media (min-width: 768px) {
  .other-prod-info {
    margin-top: 128px;
    margin-bottom: 57px;
    min-height: 776px;
  }
}

.other-prod-info .bg {
  background-image: url("./../assets/bg/inside-product-line-bg.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 282px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: -1;
  transform: translateY(-50%);
}

@media (min-width: 576px) {
  .other-prod-info .bg {
    height: 382px;
  }
}

@media (min-width: 768px) {
  .other-prod-info .bg {
    height: 582px;
    top: 0;
    transform: translateY(0);
  }
}

@media (min-width: 1440px) {
  .other-prod-info .bg {
    height: 776px;
  }
}

.other-prod-info .content {
  max-width: 1110px;
  width: calc(100% - (20px * 2));
  margin: 0 auto;
  padding: 30px 20px 27px 20px;
}

@media (min-width: 576px) {
  .other-prod-info .content {
    padding: calc(169px / 2) 20px 27px 20px;
  }
}

@media (min-width: 768px) {
  .other-prod-info .content {
    padding: calc(169px / 1.8) 80px 37px 80px;
    width: calc(100% - (80px * 2));
  }
}

@media (min-width: 1250px) {
  .other-prod-info .content {
    width: calc(100% - (165px * 2));
  }
}

@media (min-width: 1440px) {
  .other-prod-info .content {
    padding: 169px 165px 47px 165px;
  }
}

.other-prod-info .content .title {
  font-size: 32px;
  line-height: 46px;
}

@media (min-width: 768px) {
  .other-prod-info .content .title {
    font-size: 50px;
    line-height: 64px;
  }
}

@media (min-width: 1250px) {
  .other-prod-info .content .title {
    font-size: 70px;
    line-height: 84px;
  }
}

.other-prod-info .row-info-1,
.other-prod-info .row-info-2 {
  max-width: 1110px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
}

@media (min-width: 768px) {
  .other-prod-info .row-info-1,
  .other-prod-info .row-info-2 {
    padding: 0 80px;
  }
}

@media (min-width: 1250px) {
  .other-prod-info .row-info-1,
  .other-prod-info .row-info-2 {
    padding: 0 165px;
  }
}

.other-prod-info .row-info-1 .info .title,
.other-prod-info .row-info-2 .info .title {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.other-prod-info .row-info-1 .info .des,
.other-prod-info .row-info-2 .info .des {
  font-size: 16px;
  line-height: 24px;
  padding-right: 10px;
}

.other-prod-info .row-info-1 {
  margin-bottom: 0px;
}

@media (min-width: 768px) {
  .other-prod-info .row-info-1 {
    margin-bottom: 41px;
  }
}

.other-prod-info .row-info-1 .info {
  width: 100%;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .other-prod-info .row-info-1 .info {
    width: 20%;
    margin-bottom: 0;
  }
}

.other-prod-info .row-info-2 {
  padding-bottom: calc(219px / 2);
}

@media (min-width: 768px) {
  .other-prod-info .row-info-2 {
    padding-bottom: calc(219px / 1.8);
  }
}

@media (min-width: 1440px) {
  .other-prod-info .row-info-2 {
    padding-bottom: 219px;
  }
}

.other-prod-info .row-info-2 .info {
  width: 100%;
}

.other-prod-info .row-info-2 .info:last-child {
  width: 100%;
}

@media (min-width: 768px) {
  .other-prod-info .row-info-2 .info {
    width: 40%;
  }

  .other-prod-info .row-info-2 .info:last-child {
    width: 60%;
  }
}

.other-prod-info .row-info-2 .info:last-child {
  margin-top: 20px;
}

@media (min-width: 1250px) {
  .other-prod-info .row-info-2 .info:last-child {
    width: 60%;
  }
}

.other-prod-info.table .content {
  padding-top: 0px;
}

@media (min-width: 576px) {
  .other-prod-info.table .content {
    padding-top: 80px;
  }
}

.other-prod-info .overflow-hidden {
  overflow-x: hidden;
  max-width: 1110px;
  width: calc(100% - (20px * 2));
  margin: 0 auto;
  padding: 0 20px;
}

@media (min-width: 768px) {
  .other-prod-info .overflow-hidden {
    width: calc(100% - (80px * 2));
    padding: 0 80px;
  }
}

@media (min-width: 1250px) {
  .other-prod-info .overflow-hidden {
    width: calc(100% - (165px * 2));
    padding: 0 165px;
  }
}

.other-prod-info .specification-table {
  width: 100%;
  overflow-x: auto;
}

.other-prod-info .specification-table table {
  width: 100%;
  min-width: 500px;
}

@media (min-width: 576px) {
  .other-prod-info .specification-table table {
    min-width: 700px;
  }
}

.other-prod-info .specification-table table th,
.other-prod-info .specification-table table td {
  border: 1px solid #ffffff;
  padding: 5px;
  font-size: 14px;
}

.other-prod-info .specification-table table th:nth-child(1),
.other-prod-info .specification-table table td:nth-child(1) {
  text-align: left;
}

.other-prod-info .specification-table table th:nth-child(2),
.other-prod-info .specification-table table th:nth-child(3),
.other-prod-info .specification-table table th:nth-child(4),
.other-prod-info .specification-table table th:nth-child(5),
.other-prod-info .specification-table table td:nth-child(2),
.other-prod-info .specification-table table td:nth-child(3),
.other-prod-info .specification-table table td:nth-child(4),
.other-prod-info .specification-table table td:nth-child(5) {
  text-align: center;
}

@media (min-width: 576px) {
  .other-prod-info .specification-table table th,
  .other-prod-info .specification-table table td {
    border: 2px solid #ffffff;
    padding: 10px;
    font-size: 16px;
  }
}

.other-prod-info .specification-list {
  max-width: 1110px;
  width: calc(100% - (20px * 2));
  margin: 0 auto;
  padding: 0 20px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .other-prod-info .specification-list {
    width: calc(100% - (80px * 2));
    padding: 0 80px;
  }
}

@media (min-width: 1250px) {
  .other-prod-info .specification-list {
    width: calc(100% - (165px * 2));
    padding: 0 165px;
  }
}

.other-prod-info .specification-list .row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.other-prod-info .specification-list .row .col-50 {
  box-sizing: border-box;
  width: 100%;
  padding-bottom: 30px;
}

.other-prod-info .specification-list .row .col-50:first-child {
  padding-right: 20px;
}

@media (min-width: 576px) {
  .other-prod-info .specification-list .row .col-50 {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .other-prod-info .specification-list .row .col-50:first-child {
    padding-right: 50px;
  }
}

.other-prod-info .specification-list .row .col-50 .title {
  font-size: 25px;
  margin-bottom: 20px;
}

.other-prod-info .specification-list .row .col-50 .info {
  padding-bottom: 8px;
}

.prod-variations .title {
  font-size: 70px;
  line-height: 84px;
  text-align: center;
}

.prod-variations .product-list {
  padding: 0;
}

.image-preview {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background: rgba(6, 2, 51, 0.7);
  display: none;
  opacity: 0;
  justify-content: center;
  align-items: center;
}

.image-preview.-active {
  display: flex;
}

.image-preview img {
  max-width: 80vw;
  max-height: 90vh;
}

.image-preview .icon {
  cursor: pointer;
}

.image-preview .icon.-arrow-right {
  margin-left: -20px;
  z-index: 2;
}

@media (min-width: 992px) {
  .image-preview .icon.-arrow-right {
    margin-left: -37px;
  }
}

.image-preview .icon.-arrow-left {
  margin-right: -20px;
  z-index: 2;
}

@media (min-width: 992px) {
  .image-preview .icon.-arrow-left {
    margin-right: -37px;
  }
}

.image-preview #closeGallery {
  position: fixed;
  z-index: 3;
  top: 20px;
  right: 20px;
  height: 30px;
  width: 30px;
}

.row-with-img {
  padding: 0 20px 27px 20px;
  width: calc(100% - (20px * 2));
  max-width: 1110px;
  margin: 0 auto;
  display: flex;
}

@media (min-width: 768px) {
  .row-with-img {
    padding: calc(169px / 1.8) 80px 37px 80px;
    width: calc(100% - (80px * 2));
  }
}

@media (min-width: 1250px) {
  .row-with-img {
    padding: 0 165px;
    width: calc(100% - (165px * 2));
  }
}

@media screen and (max-width: 1000px) {
  .row-with-img {
    flex-wrap: wrap;
  }
}

.row-with-img .row-info .row-info-1,
.row-with-img .row-info .row-info-2 {
  padding: 0;
}

.row-with-img .row-certificate {
  padding-left: 20px;
}

@media screen and (max-width: 1000px) {
  .row-with-img .row-certificate {
    width: 100%;
    margin-top: 50px;
    padding-left: 0;
  }
}

.row-with-img .row-certificate img {
  width: 100%;
  max-width: 400px;
}

#topMenuRow {
  transform: translateY(-30px);
  opacity: 0;
}

#section1Title {
  transform: translateY(30px);
  opacity: 0;
}

#stickyBtn {
  transform: translateX(30px);
  opacity: 0;
}

#section2Box {
  background-size: 0;
  background-position: left;
  background-repeat: no-repeat;
}

#section2title {
  transform: translatey(-30px);
  opacity: 0;
}

#section2Img {
  transform: translateX(-30px);
  opacity: 0;
}

#section2text {
  opacity: 0;
}

#section3Bg {
  opacity: 0;
}

#productList .section-title {
  opacity: 0;
}

#productList #section3Card3 .title,
#productList #section3Card2 .title,
#productList #section3Card1 .title,
#productList #section3Card2,
#productList #section3Card3,
#productList #section3Card1 {
  transform: translateX(30px);
  opacity: 0;
}

.section-content.content-padding .full-width {
  overflow: hidden;
}

#section4card {
  transform: translateX(100%);
}

#section4Card1 .title {
  transform: translateX(-30px);
  opacity: 0;
}

#section4Card1 .des-title {
  transform: translateX(-30px);
  opacity: 0;
}

#section4Card1 .description {
  transform: translateX(-30px);
  opacity: 0;
}

#section5Title {
  transform: translateY(30px);
  opacity: 0;
}

#section5Bg,
#section5ArrowLeft,
#section5ArrowRight {
  opacity: 0;
}

#cert1,
#cert2,
#cert3,
#cert4 {
  transform: translateX(30px);
  opacity: 0;
}

#cert1 .title,
#cert2 .title,
#cert3 .title,
#cert4 .title {
  transform: translateX(30px);
  opacity: 0;
}

#section6Bg {
  opacity: 0;
}

#section6Left {
  transform: translateX(-30px);
  opacity: 0;
}

#section6Right {
  transform: translateX(30px);
  opacity: 0;
}

#section6footer {
  transform: translateY(30px);
  opacity: 0;
}

#about-us .head-content .columns-1 {
  transform: translateX(30px);
  opacity: 0;
}

#worldMap .map .world-map {
  opacity: 0;
  transform: translateX(30px);
}

#worldMap > .title {
  opacity: 0;
  transform: translateY(30px);
}

#worldMap .info {
  opacity: 0;
  transform: translateX(-30px);
}

.footer-top .-bg {
  opacity: 0;
}

#footerTop .col-right,
#footerTop .col-left .title,
#footerTop .col-left .question {
  opacity: 0;
  transform: translateY(30px);
}

.footer-bottom {
  opacity: 0;
}

#aboutUsDes .description,
#aboutUsDes .title {
  transform: translateX(-30px);
  opacity: 0;
}

#aboutUsImg .img-2 {
  transform: translateX(30px);
  opacity: 0;
}

#aboutUsImg .img-1 {
  transform: translateX(-30px);
  opacity: 0;
}

#aboutProductsSlider {
  transform: translateX(-30px);
  opacity: 0;
}

#aboutProductsDes {
  transform: translateY(30px);
  opacity: 0;
}

#aboutProductsTitle {
  transform: translateX(30px);
  opacity: 0;
}

#productPageTitle {
  transform: translateX(-30px);
  opacity: 0;
}

#productPageDescription {
  transform: translateX(30px);
  opacity: 0;
}

#contactUsTitle {
  transform: translateY(30px);
  opacity: 0;
}

.contacts-row.-first .contact {
  transform: translateX(-30px);
  opacity: 0;
}

#contactFormContainer {
  transform: translateX(30px);
  opacity: 0;
}

#contactFormCard {
  transform: translateX(30px);
  opacity: 0;
}

#productInformation {
  transform: translateX(-30px);
  opacity: 0;
}

.product-row,
#privacyPolicyTitle,
#productImages,
.about-prod .right .info,
#mobileDescription {
  transform: translateY(30px);
  opacity: 0;
}

.animate__fromTopToBottom {
  -webkit-animation: fromTopToBottom 0.5s forwards;
          animation: fromTopToBottom 0.5s forwards;
}

.animate__fromBottomToTop {
  -webkit-animation: fromBottomToTop 0.8s forwards;
          animation: fromBottomToTop 0.8s forwards;
}

.animate__fromRightToLeft {
  -webkit-animation: fromRightToLeft 0.8s forwards;
          animation: fromRightToLeft 0.8s forwards;
}

.animate__fromRightToLeftExtra {
  -webkit-animation: fromRightToLeftExtra 0.8s forwards;
          animation: fromRightToLeftExtra 0.8s forwards;
}

@media (min-width: 576px) {
  .animate__fromRightToLeftExtra {
    -webkit-animation: fromRightToLeftExtraSm 0.8s forwards;
            animation: fromRightToLeftExtraSm 0.8s forwards;
  }
}

@media (min-width: 992px) {
  .animate__fromRightToLeftExtra {
    -webkit-animation: fromRightToLeftExtraLg 0.8s forwards;
            animation: fromRightToLeftExtraLg 0.8s forwards;
  }
}

.animate__fromLeftToRight {
  -webkit-animation: fromLeftToRight 0.8s forwards;
          animation: fromLeftToRight 0.8s forwards;
}

.animate__rolBgFromLeft {
  -webkit-animation: rolBgFromLeft 0.8s forwards;
          animation: rolBgFromLeft 0.8s forwards;
}

.animate__fadeIn {
  -webkit-animation: fadeIn 0.8s forwards;
          animation: fadeIn 0.8s forwards;
}

.animate__fadeOutSimple {
  -webkit-animation: fadeOutSimple 0.4s forwards;
          animation: fadeOutSimple 0.4s forwards;
}

.animate__fadeOut {
  -webkit-animation: fadeOut 0.4s forwards;
          animation: fadeOut 0.4s forwards;
}

.animate__section3Title {
  -webkit-animation: section3Title 0.5s forwards;
          animation: section3Title 0.5s forwards;
}

.animate__section4Card {
  -webkit-animation: section4Card 1s forwards;
          animation: section4Card 1s forwards;
}

.animate__FadeOutFromCurrentPosition {
  -webkit-animation: FadeOutFromCurrentPosition 0.8s forwards;
          animation: FadeOutFromCurrentPosition 0.8s forwards;
}

@-webkit-keyframes fromTopToBottom {
  from {
    transform: translateY(-30px);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fromTopToBottom {
  from {
    transform: translateY(-30px);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@-webkit-keyframes fromBottomToTop {
  from {
    transform: translateY(30px);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fromBottomToTop {
  from {
    transform: translateY(30px);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@-webkit-keyframes fromRightToLeft {
  from {
    transform: translateX(30px);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes fromRightToLeft {
  from {
    transform: translateX(30px);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@-webkit-keyframes fromRightToLeftExtra {
  from {
    transform: translateX(30px);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes fromRightToLeftExtra {
  from {
    transform: translateX(30px);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@-webkit-keyframes fromRightToLeftExtraSm {
  from {
    opacity: 0;
    transform: translateX(30px);
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes fromRightToLeftExtraSm {
  from {
    opacity: 0;
    transform: translateX(30px);
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@-webkit-keyframes fromRightToLeftExtraLg {
  from {
    opacity: 0;
    transform: translateX(30px);
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes fromRightToLeftExtraLg {
  from {
    opacity: 0;
    transform: translateX(30px);
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@-webkit-keyframes fromLeftToRight {
  from {
    transform: translateX(-30px);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes fromLeftToRight {
  from {
    transform: translateX(-30px);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@-webkit-keyframes rolBgFromLeft {
  from {
    background-size: 0;
  }

  to {
    background-size: 100%;
  }
}

@keyframes rolBgFromLeft {
  from {
    background-size: 0;
  }

  to {
    background-size: 100%;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOutSimple {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOutSimple {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateX(0px);
  }

  to {
    opacity: 0;
    transform: translateX(-30px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateX(0px);
  }

  to {
    opacity: 0;
    transform: translateX(-30px);
  }
}

@-webkit-keyframes section3Title {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes section3Title {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes section4Card {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes section4Card {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@-webkit-keyframes FadeOutFromCurrentPosition {
  from {
    transform: translateY(0px);
    opacity: 1;
  }

  to {
    transform: translateY(30px);
    opacity: 0;
  }
}

@keyframes FadeOutFromCurrentPosition {
  from {
    transform: translateY(0px);
    opacity: 1;
  }

  to {
    transform: translateY(30px);
    opacity: 0;
  }
}

@media (min-width: 1250px) {
  html:lang(it) .page.-header .columns-1 .title-right {
    font-size: 40px;
    line-height: 50px;
  }

  html:lang(it) .certificate .content .title {
    font-size: 60px;
    line-height: 74px;
  }

  html:lang(it) .product-row.right-align .title,
  html:lang(it) .product-row.left-align .title {
    font-size: 40px;
    line-height: 54px;
  }
}

html:lang(it) .page.-header .columns-2 .col-right .tabs ul li {
  margin-right: 4px;
  margin-top: 15px;
}

@media screen and (max-width: 1150px) {
  html:lang(it) #products .columns-2 .col-right {
    display: none;
  }
}

html:lang(ru) #contactFormCard .row .title {
  text-align: right;
}

@media (min-width: 1250px) {
  html:lang(ru) .section-content.footer-content .footer.-top .content .left .first,
  html:lang(ru) .page.-header .columns-1 .title-right,
  html:lang(ru) .page .footer-top .content .col-left .question {
    font-size: 40px;
    line-height: 50px;
  }

  html:lang(ru) .section-content.footer-content .footer.-top .content .left .second,
  html:lang(ru) .page .footer-top .content .col-left .title {
    font-size: 60px;
    line-height: 74px;
  }

  html:lang(ru) .product-row.right-align .title,
  html:lang(ru) .product-row.left-align .title {
    font-size: 40px;
    line-height: 54px;
  }

  html:lang(ru) .page.-header .columns-2 .col-left .title {
    font-size: 60px;
    line-height: 74px;
  }
}

html:lang(ru) .page.-header .columns-2 .col-right .tabs ul li {
  font-size: 13px;
}

html:lang(ru) .page.-header .columns-2 .col-right .tabs ul li {
  margin-right: 4px;
  margin-top: 15px;
}

@media screen and (max-width: 1150px) {
  html:lang(ru) #products .columns-2 .col-right {
    display: none;
  }
}

html:lang(ru) .contacts-row .send-button {
  min-width: 175px;
}

html:lang(lt) .section-content .products .product-list .product-card .title {
  font-size: 16px;
  line-height: 21px;
}

@media (min-width: 992px) {
  html:lang(lt) .section-content .products .product-list .product-card .title {
    font-size: 25px;
    line-height: 30px;
  }
}

@media (min-width: 1440px) {
  html:lang(lt) .section-content .products .product-list .product-card .title {
    font-size: 32px;
    line-height: 40px;
  }
}

@media (min-width: 1250px) {
  html:lang(lt) .page .footer-top .content .col-left .title,
  html:lang(lt) .section-content.footer-content .footer.-top .content .left .second {
    font-size: 55px;
    line-height: 69px;
  }
}

@media (min-width: 1250px) {
  html:lang(lt) .page .footer-top .content .col-left .question,
  html:lang(lt) .section-content.footer-content .footer.-top .content .left .first {
    font-size: 35px;
    line-height: 45px;
  }
}

html:lang(lt) .page.-header .columns-2 .col-right .tabs ul li:last-child {
  margin-top: 14px;
}

@media (min-width: 768px) {
  html:lang(lt) .product-row.right-align .title,
  html:lang(lt) .product-row.left-align .title {
    font-size: 30px;
    line-height: 40px;
  }
}

@media (min-width: 1250px) {
  html:lang(lt) .product-row.right-align .title,
  html:lang(lt) .product-row.left-align .title {
    font-size: 35px;
    line-height: 47px;
  }
}

@media (min-width: 768px) {
  html:lang(lt) .page.-header .columns-2 .col-left .title {
    font-size: 35px;
    line-height: 45px;
  }
}

@media (min-width: 1250px) {
  html:lang(lt) .page.-header .columns-2 .col-left .title {
    font-size: 45px;
    line-height: 50px;
  }
}

