html:lang(ru) {
    #contactFormCard .row .title {
        text-align: right;
    }

    @include breakpoint(xl) {
        .section-content.footer-content .footer.-top .content .left .first,
        .page.-header .columns-1 .title-right,
        .page .footer-top .content .col-left .question {
            font-size: 40px;
            line-height: 50px;
        }

        .section-content.footer-content .footer.-top .content .left .second,
        .page .footer-top .content .col-left .title {
            font-size: 60px;
            line-height: 74px;
        }

        .product-row.right-align .title,
        .product-row.left-align .title {
            font-size: 40px;
            line-height: 54px;
        }

        .page.-header .columns-2 .col-left .title {
            font-size: 60px;
            line-height: 74px;
        }
    }

    .page.-header .columns-2 .col-right .tabs ul li {
        font-size: 13px;
    }

    .page.-header .columns-2 .col-right .tabs ul li {
        margin-right: 4px;
        margin-top: 15px;
    }

    @media screen and (max-width: 1150px) {
        #products .columns-2 .col-right {
            display: none;
        }
    }

    .contacts-row .send-button {
        min-width: 175px;
    }
}
