$breakpoints: (
    xs: 420px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1250px,
    xxl: 1440px,
    xxxl: 1740px
);

// Media query mixins
@mixin breakpoint($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
            @content;
        }
    }
}
