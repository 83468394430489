.main-background {
    background-color: $page-background;
    color: $white;
    font-family: PF DinDisplay Pro;

    #fullPageScroll section {
        height: 100vh;
    }
}

body {
    max-width: 1920px !important;
    margin: 0 auto !important;
    position: relative;
}

#topMenuRow {
    position: relative;
    z-index: 9;
}