#products {
    overflow-x: hidden;

    .columns-2 {
        .col-right {
            display: none;

            @include breakpoint(lg) {
                display: block;
            }
        }
    }

    .page.-page-content a {
        box-sizing: border-box;
    }
}

.product-row {
    padding-top: 100px;
    width: 100%;
    display: flex;

    @media screen and (max-width: 767px) {
        flex-wrap: wrap;
    }

    @include breakpoint(md) {
        padding-top: 180px;
    }

    @include breakpoint(lg) {
        padding-top: 215px;
    }

    &.right-align,
    &.left-align {
        position: relative;

        .prod-info {
            width: 100%;
            order: 1;

            @include breakpoint(md) {
                width: 42%;
                order: 2;
            }
        }

        .title {
            font-size: 32px;
            line-height: 49px;
            width: 100%;
            margin-bottom: 20px;

            &.-active {
                text-decoration-line: underline;
            }

            @include breakpoint(md) {
                font-size: 50px;
                line-height: 64px;
                max-width: 268px;
                margin-bottom: 50px;
            }

            @include breakpoint(xl) {
                font-size: 70px;
                line-height: 84px;
                max-width: 332px;
            }
        }

        .description {
            box-sizing: border-box;
            font-size: 14px;
            line-height: 18px;
            width: 100%;
            display: none;

            @include breakpoint(md) {
                font-size: 16px;
                line-height: 22px;
                display: block;
            }

            @include breakpoint(lg) {
                // max-width: 45%;
                line-height: 24px;
            }
        }

        .bg {
            width: 100%;
            max-height: 507px;
            border-radius: 20px;
            position: relative;

            order: 2;

            @include breakpoint(md) {
                width: 58%;
                order: 1;
            }

            .shadow {
                border-radius: 20px;
                height: 100%;
                width: 100%;
                position: absolute;
                z-index: -1;
                opacity: 0;

                &.-active {
                    opacity: 1;
                }
            }

            img {
                border-radius: 20px;
                object-position: center center;
                object-fit: cover;
                width: 100%;
                height: calc(100% + 50px);
            }
        }
    }

    &.left-align {
        .bg {
            .shadow {
                top: -12px;
                left: 7px;
                background: linear-gradient(
                    266.24deg,
                    rgba(20, 156, 255, 0) 0%,
                    rgba(41, 22, 255, 0.42) 96.11%
                );

                @include breakpoint(md) {
                    top: -44px;
                    left: 57px;
                }
            }
        }
        .title {
            padding-right: 40px;
        }

        .description {
            padding-right: 40px;
        }

        @include breakpoint(md) {
            .title {
                padding-right: 0;
                padding-left: 40px;
            }
            .description {
                padding-right: 0;
                padding-left: 40px;
            }
        }

        @include breakpoint(xl) {
            .title {
                padding-left: 80px;
            }

            .description {
                padding-left: 80px;
            }
        }
    }

    &.right-align {
        .bg {
            order: 2;

            .shadow {
                top: -12px;
                right: 7px;
                background: linear-gradient(
                    90.24deg,
                    rgba(20, 156, 255, 0) 0%,
                    rgba(41, 22, 255, 0.42) 96.11%
                );

                @include breakpoint(md) {
                    top: -44px;
                    right: 57px;
                }
            }
        }

        .prod-info {
            order: 1;
        }

        .title {
            padding-right: 40px;
        }

        .description {
            padding-right: 40px;
        }

        @include breakpoint(xl) {
            .title {
                padding-right: 80px;
            }

            .description {
                padding-right: 80px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    #products .page.-page-content a:first-child .product-row {
        padding-top: 0;
    }
    #products .page.-page-content a:first-child .product-row .title {
        max-width: 100%;
        //max-width: 200px;
    }
    #products .page.-page-content a:last-child .product-row {
        padding-bottom: 80px;
    }
}
