.sticky-btn {
    // position: absolute;
    position: fixed;
    z-index: 999;
    top: 40%;
    // top: 50%;
    display: block;
    transform: translateY(-70%);
    padding: 19px 10px 19px 11px;
    background: linear-gradient(
        135deg,
        rgba(0, 222, 236, 0.32) 0%,
        #334e8d 100%
    );
    border-radius: 8px 0px 0px 8px;
    cursor: pointer;
    right: 0;

    @media screen and (max-width: 991px) {
        top: 50%;
        transform: translateY(-30%);
    }

    // &.en { right: -79px}
    // &.lt{ right: -92px}
    // &.it { right: -62px}
    // &.ru { right: -93px}

    .text {
        text-transform: uppercase;
        padding: 6px 0px 7px 0px;
        font-weight: bold;
        font-size: 10px;
        line-height: 13px;
        letter-spacing: 0.14em;
        writing-mode: vertical-lr;
        transform: rotate(180deg);

        @include breakpoint(md) {
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.1em;
            padding: 8px 0px 9px 0px;
        }

        @include breakpoint(xl) {
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.14em;
            padding: 10px 0px 11px 0px;
        }
    }

    .icon {
        // transform: rotate(90deg);
        &.-envelope {
            height: 14px;
            width: 10px;
        }

        &.-circle-arrow {
            width: 11px;
            height: 11px;
        }

        @include breakpoint(md) {
            &.-envelope {
                height: 16px;
                width: 12px;
            }

            &.-circle-arrow {
                width: 13px;
                height: 13px;
            }
        }

        @include breakpoint(xl) {
            &.-envelope {
                height: 18px;
                width: 14px;
            }

            &.-circle-arrow {
                width: 15px;
                height: 15px;
            }
        }
    }
}
