.about-us {
    margin-top: 60px;
    margin-bottom: 0px;

    @include breakpoint(lg) {
        // margin-bottom: 78px;
    }

    .about {
        flex-wrap: wrap;
        @include breakpoint(lg) {
            flex-wrap: nowrap;
        }

        .col-6 {
            width: 100%;

            @include breakpoint(lg) {
                width: 50%;
            }
        }

        .left {
            padding-right: 20px;
            padding-bottom: 20px;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;

            @include breakpoint(xl) {
                padding-right: 97px;
            }

            .title {
                font-weight: bold;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.09em;
                text-transform: uppercase;
                margin-bottom: 28px;
            }

            .sub-title {
                font-size: 27px;
                line-height: 33px;
                margin-bottom: 28px;
            }

            .description {
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 10px;
            }
        }

        .right {
            position: relative;
            min-height: 100px;

            margin: 0 auto;
            margin-top: 30px;
            transform: translateX(-50px);
            width: auto;

            @include breakpoint(lg) {
                margin-top: 0;
                min-height: 400px;
                margin: 0;
                transform: translateX(0px);
            }

            .img-1 {
                width: 100%;
                height: auto;

                position: absolute;
                top: -45px;
                left: 80px;
                z-index: -1;

                @include breakpoint(sm) {
                    top: -55px;
                }

                @media screen and (max-width: 470px) {
                    left: 60px;
                }
                @media screen and (max-width: 350px) {
                    left: 40px;
                }
            }

            .img-2 {
                min-width: 100%;
                height: 100%;
                transform: translateY(30px);
                display: flex;
                align-items: flex-end;
            }

            @media screen and (max-width: 991px) {
                .img-1,
                .img-2 {
                    max-width: 400px;
                    min-width: 0;
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-position: bottom;
                object-fit: contain;
                border-radius: 2rem;
                object-fit: cover;
            }
        }
    }
}

.certificate {
    margin-bottom: 72px;

    width: 100%;
    position: relative;
    min-height: 0px;

    .bg {
        background-image: url("./../../assets/bg/line.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        width: 100%;
        height: 587px;
        position: absolute;
        z-index: -1;
        top: 57px;
        left: 0;
    }

    @include breakpoint(lg) {
        min-height: 670px;
        .bg {
            height: 670px;
        }
    }

    @include breakpoint(xxl) {
        min-height: 900px;
        .bg {
            height: 900px;
        }
    }

    .content {
        min-height: 100%;
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: center;

        @include breakpoint(lg) {
            justify-content: flex-start;
        }

        .carusel {
            max-width: 450px;
            max-height: 450px;

            margin-right: 0px;
            order: 3;
            img {
                border-radius: 10px;
            }

            display: flex;
            justify-content: center;

            .slick-dots {
                position: absolute;
                bottom: -40px;
                display: flex;

                button {
                    border-radius: 2rem;
                    height: 10px;
                    width: 10px;
                    background: transparent;
                    border: 1px solid $light-blue;
                    padding: 0;
                    color: transparent;
                    margin: 0 5px;
                }

                .slick-active button {
                    background: $light-blue;
                }
            }
        }

        .description {
            font-size: 16px;
            line-height: 24px;
            margin-right: 0px;
            order: 2;
            margin-bottom: 30px;
        }

        .title {
            order: 1;
            font-size: 31px;
            line-height: 31px;
            // transform: rotate(90deg);
            writing-mode: horizontal-tb;
            margin-bottom: 20px;
            margin-top: 30px;
            text-align: left;

            br {
                display: none;
            }

            padding-top: 30px;

            @include breakpoint(sm) {
                text-align: center;
            }

            @include breakpoint(md) {
                margin-bottom: 30px;
                padding-top: 0;
            }
        }

        .carusel {
            width: 88vw;
            margin: 0 auto;
        }

        .description,
        .title {
            width: 100%;
        }

        .description,
        .title {
            padding-right: 20px;
        }

        @include breakpoint(lg) {
            flex-wrap: nowrap;
            align-items: center;

            .carusel,
            .description,
            .title {
                width: auto;
            }

            .carusel {
                order: 1;
                margin-right: 28px;
                // margin-right: 58px;
            }

            .description {
                order: 2;
                margin-right: 28px;
                // margin-right: 79px;
                margin-bottom: 0;
            }

            .title {
                order: 3;
                writing-mode: vertical-rl;
                font-size: 50px;
                line-height: 50px;
                text-align: left;
                margin-bottom: 0;

                br {
                    display: block;
                }
            }
        }

        @include breakpoint(xl) {
            .title {
                font-size: 70px;
                line-height: 70px;
            }
        }
    }
}

.map-section {
    padding: 0 20px;
    margin-bottom: -50px;
    max-width: 1168px;
    margin: 0 auto;

    @include breakpoint(md) {
        padding: 31px 80px 0 50px;
    }

    @include breakpoint(xl) {
        padding: 71px 107px 0 165px;
    }

    .title {
        font-size: 32px;
        line-height: 46px;
        text-align: center;

        @include breakpoint(md) {
            font-size: 50px;
            line-height: 64px;
        }

        @include breakpoint(xl) {
            font-size: 70px;
            line-height: 84px;
        }
    }

    .map {
        display: flex;
        align-items: top;
        justify-content: center;
        padding-top: 90px;
        position: relative;
        margin-top: 20px;

        margin-bottom: 30px;

        @include breakpoint(sm) {
            margin-bottom: 0px;
        }

        @include breakpoint(lg) {
            margin-top: 0;
            padding-top: 40px;
            align-items: center;
            justify-content: space-between;
        }

        .map-info {
            padding-top: 20px;
            position: absolute;
            display: flex;
            top: 0px;

            @include breakpoint(lg) {
                top: unset;
                display: block;
            }
            .info {
                margin-bottom: 46px;
                margin-left: 15px;
                margin-right: 15px;

                @include breakpoint(lg) {
                    margin-left: 0px;
                    margin-right: 0px;
                }

                .percentage {
                    font-size: 25px;
                    line-height: 35px;
                    text-align: center;
                    transition: color 0.4s linear;

                    @include breakpoint(md) {
                        font-size: 35px;
                        line-height: 45px;
                    }

                    @include breakpoint(lg) {
                        text-align: left;
                    }

                    @include breakpoint(xl) {
                        font-size: 50px;
                        line-height: 60px;
                    }
                }

                .title {
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 0.09em;
                    text-transform: uppercase;
                    text-align: center;
                    transition: color 0.4s linear;

                    @include breakpoint(md) {
                        font-size: 14px;
                        line-height: 17px;
                    }

                    @include breakpoint(lg) {
                        text-align: left;
                    }

                    @include breakpoint(xl) {
                        font-size: 16px;
                        line-height: 19px;
                    }
                }

                &.active {
                    .percentage,
                    .title {
                        color: $blue;
                    }
                }
            }
        }

        .world-map {
            margin-top: 0px;
            margin-left: 0px;
            width: 100%;

            @include breakpoint(lg) {
                margin-left: 50px;
            }

            svg {
                width: 100%;
                height: auto;

                path {
                    transition: fill-opacity 0.4s linear,
                        stroke-opacity 0.4s linear;

                    // fill: green !important;
                }
            }
        }
    }
}

#aboutUsImg {
    @media screen and (max-width: 991px) {
        margin-bottom: 50px;
        margin-top: 50px;
    }
    @media screen and (max-width: 590px) {
        margin-left: 50px;
    }
}
