.preeloader {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background: #000;
    // background: linear-gradient(rgba(2, 0, 36, 1) 0%, rgba(0, 0, 0, 1) 100%);
    background: #060233;
    z-index: 999999999999;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease-out;

    img {
        opacity: 0;
        max-width: 400px;
        animation: logoFade 0.8s 0.8s forwards;

        @media screen and (max-width: 575px) {
            width: 80%;
        }
    }
}

#home .preeloader {
    display: flex;
}

@keyframes logoFade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
