// elements before animations
#topMenuRow {
    transform: translateY(-30px);
    opacity: 0;
}

#section1Title {
    transform: translateY(30px);
    opacity: 0;
}

#stickyBtn {
    transform: translateX(30px);
    opacity: 0;
}

// Home page Section 2
#section2Box {
    background-size: 0;
    background-position: left;
    background-repeat: no-repeat;
}

#section2title {
    transform: translatey(-30px);
    opacity: 0;
}

#section2Img {
    transform: translateX(-30px);
    opacity: 0;
}

#section2text {
    opacity: 0;
}

// Home page section 3

#section3Bg {
    opacity: 0;
}

#productList {
    .section-title {
        opacity: 0;
    }

    #section3Card3 .title,
    #section3Card2 .title,
    #section3Card1 .title,
    #section3Card2,
    #section3Card3,
    #section3Card1 {
        transform: translateX(30px);
        opacity: 0;
    }
}

// Home page section 4
.section-content.content-padding .full-width {
    overflow: hidden;
}
#section4card {
    transform: translateX(100%);
}

#section4Card1 {
    .title {
        transform: translateX(-30px);
        opacity: 0;
    }
    .des-title {
        transform: translateX(-30px);
        opacity: 0;
    }
    .description {
        transform: translateX(-30px);
        opacity: 0;
    }
}

// Home page section 5

#section5Title {
    transform: translateY(30px);
    opacity: 0;
}

#section5Bg,
#section5ArrowLeft,
#section5ArrowRight {
    opacity: 0;
}

#cert1,
#cert2,
#cert3,
#cert4 {
    transform: translateX(30px);
    opacity: 0;

    .title {
        transform: translateX(30px);
        opacity: 0;
    }
}

// Home page section 6
#section6Bg {
    opacity: 0;
}

#section6Left {
    transform: translateX(-30px);
    opacity: 0;
}
#section6Right {
    transform: translateX(30px);
    opacity: 0;
}
#section6footer {
    transform: translateY(30px);
    opacity: 0;
}

#about-us .head-content .columns-1 {
    transform: translateX(30px);
    opacity: 0;
}

#worldMap .map .world-map {
    opacity: 0;
    transform: translateX(30px);
}

#worldMap > .title {
    opacity: 0;
    transform: translateY(30px);
}
#worldMap .info {
    opacity: 0;
    transform: translateX(-30px);
}

.footer-top .-bg {
    opacity: 0;
}

#footerTop .col-right,
#footerTop .col-left .title,
#footerTop .col-left .question {
    opacity: 0;
    transform: translateY(30px);
}

.footer-bottom {
    opacity: 0;
    // transform: translateY(30px);
}

#aboutUsDes .description,
#aboutUsDes .title {
    transform: translateX(-30px);
    opacity: 0;
}

#aboutUsImg .img-2 {
    transform: translateX(30px);
    opacity: 0;
}

#aboutUsImg .img-1 {
    transform: translateX(-30px);
    opacity: 0;
}

#aboutProductsSlider {
    transform: translateX(-30px);
    opacity: 0;
}

#aboutProductsDes {
    transform: translateY(30px);
    opacity: 0;
}

#aboutProductsTitle {
    transform: translateX(30px);
    opacity: 0;
}

#productPageTitle {
    transform: translateX(-30px);
    opacity: 0;
}
#productPageDescription {
    transform: translateX(30px);
    opacity: 0;
}

#contactUsTitle {
    transform: translateY(30px);
    opacity: 0;
}

.contacts-row.-first .contact {
    transform: translateX(-30px);
    opacity: 0;
}

#contactFormContainer {
    transform: translateX(30px);
    opacity: 0;
}

#contactFormCard {
    transform: translateX(30px);
    opacity: 0;
}

#productInformation {
    transform: translateX(-30px);
    opacity: 0;
}

.product-row,
#privacyPolicyTitle,
#productImages,
.about-prod .right .info,
#mobileDescription {
    transform: translateY(30px);
    opacity: 0;
}

// animations
.animate__fromTopToBottom {
    animation: fromTopToBottom 0.5s forwards;
}
.animate__fromBottomToTop {
    animation: fromBottomToTop 0.8s forwards;
}
.animate__fromRightToLeft {
    animation: fromRightToLeft 0.8s forwards;
}
.animate__fromRightToLeftExtra {
    animation: fromRightToLeftExtra 0.8s forwards;
    @include breakpoint(sm) {
        animation: fromRightToLeftExtraSm 0.8s forwards;
    }
    @include breakpoint(lg) {
        animation: fromRightToLeftExtraLg 0.8s forwards;
    }
}
.animate__fromLeftToRight {
    animation: fromLeftToRight 0.8s forwards;
}
.animate__rolBgFromLeft {
    animation: rolBgFromLeft 0.8s forwards;
}
.animate__fadeIn {
    animation: fadeIn 0.8s forwards;
}
.animate__fadeOutSimple {
    animation: fadeOutSimple 0.4s forwards;
}
.animate__fadeOut {
    animation: fadeOut 0.4s forwards;
}
.animate__section3Title {
    animation: section3Title 0.5s forwards;
}
.animate__section4Card {
    animation: section4Card 1s forwards;
}
.animate__FadeOutFromCurrentPosition {
    animation: FadeOutFromCurrentPosition 0.8s forwards;
}

// key frames
@keyframes fromTopToBottom {
    from {
        transform: translateY(-30px);
        opacity: 0;
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}
@keyframes fromBottomToTop {
    from {
        transform: translateY(30px);
        opacity: 0;
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}
@keyframes fromRightToLeft {
    from {
        transform: translateX(30px);
        opacity: 0;
    }

    to {
        opacity: 1;
        transform: translateX(0px);
    }
}
@keyframes fromRightToLeftExtra {
    from {
        transform: translateX(30px);
        opacity: 0;
    }

    to {
        opacity: 1;
        transform: translateX(0px);
    }
}
@keyframes fromRightToLeftExtraSm {
    from {
        opacity: 0;
        transform: translateX(30px);
    }

    to {
        opacity: 1;
        transform: translateX(0px);
    }
}
@keyframes fromRightToLeftExtraLg {
    from {
        opacity: 0;
        transform: translateX(30px);
    }

    to {
        opacity: 1;
        transform: translateX(0px)
    }
}
@keyframes fromLeftToRight {
    from {
        transform: translateX(-30px);
        opacity: 0;
    }

    to {
        opacity: 1;
        transform: translateX(0px);
    }
}
@keyframes rolBgFromLeft {
    from {
        background-size: 0;
    }

    to {
        background-size: 100%;
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
@keyframes fadeOutSimple {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translateX(0px);
    }

    to {
        opacity: 0;
        transform: translateX(-30px);
    }
}
@keyframes section3Title {
    from {
        // top: 50px;
        opacity: 0;
    }

    to {
        // top: 20px;
        opacity: 1;
    }
}
@keyframes section4Card {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}
@keyframes FadeOutFromCurrentPosition {
    from {
        transform: translateY(0px);
        opacity: 1;
    }
    to {
        transform: translateY(30px);
        opacity: 0;
    }
}
