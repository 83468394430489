.banner {
    box-sizing: border-box;
    position: absolute;
    bottom: 5px;
    right: 5px;
    // right: $page-padding;
    // width: calc(100% - (#{$page-padding} * 2));
    width: calc(100% - 10px);
    text-align: right;

    @include breakpoint(md) {
        bottom: 5px;
    }

    img {
        max-width: 100%;
    }
}
