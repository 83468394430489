html:lang(it) {
    @include breakpoint(xl) {
        .page.-header .columns-1 .title-right {
            font-size: 40px;
            line-height: 50px;
        }

        .certificate .content .title {
            font-size: 60px;
            line-height: 74px;
        }

        .product-row.right-align .title,
        .product-row.left-align .title {
            font-size: 40px;
            line-height: 54px;
        }
    }

    .page.-header .columns-2 .col-right .tabs ul li {
        margin-right: 4px;
        margin-top: 15px;
    }

    @media screen and (max-width: 1150px) {
        #products .columns-2 .col-right {
            display: none;
        }
    }
}
