.slick-initialized .slick-slide {
    padding: 0 9px;

    @include breakpoint(lg) {
        padding: 0 18px;
    }
}

#section-1 .content {
    z-index: -1;
}
.section-content .content.-first {
    z-index: -1;
}

.section-content {
    position: relative;
    height: 100%;
    width: 100%;

    .header {
        $bottom-margin: 60px;

        height: calc(100% - #{$bottom-margin});
        width: 100%;
        margin-bottom: $bottom-margin;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        &.-bg {
            background-image: url("./../../assets/bg/bg_top.png");
            background-repeat: no-repeat;
            background-position: center bottom;
            background-size: cover;

            width: 100%;
        }
    }

    .content {
        height: 100%;
        width: calc(100% - #{$page-padding} - #{$page-padding});
        max-width: 1110px;
        padding: 0 $page-padding;
        margin: 0 auto;
        position: relative;

        @include breakpoint(md) {
            padding: 0 $md-page-padding;
            width: calc(100% - #{$md-page-padding} - #{$md-page-padding});
        }
        @include breakpoint(xl) {
            padding: 0 $xl-page-padding;
            width: calc(100% - #{$xl-page-padding} - #{$xl-page-padding});
        }

        h1 {
            position: absolute;
            top: 25%;
            width: calc(100% - 40px);
            left: page-padding;
            font-size: 30px;
            line-height: 40px;
            max-width: calc(100% - #{$page-padding} - #{$page-padding} - 20px);

            @include breakpoint(md) {
                width: calc(100% - 160px);
                max-width: calc(
                    100% - #{$md-page-padding} - #{$md-page-padding}
                );
                font-size: 50px;
                line-height: 64px;
                left: $md-page-padding;
            }
            @include breakpoint(lg) {
                width: 952px;
                font-size: 60px;
                line-height: 74px;
            }
            @include breakpoint(xl) {
                max-width: calc(
                    100% - #{$xl-page-padding} - #{$xl-page-padding}
                );
                left: $xl-page-padding;
            }
        }
    }

    &.content-padding {
        max-width: 1110px;
        margin: 0 auto;
        width: calc(100% - #{$page-padding} - #{$page-padding} - 40px);
        padding: 0 $page-padding;

        @include breakpoint(md) {
            width: calc(100% - #{$md-page-padding} - #{$md-page-padding});
            padding: 0 $md-page-padding;
        }
        @include breakpoint(xl) {
            width: calc(100% - #{$xl-page-padding} - #{$xl-page-padding});
            padding: 0 $xl-page-padding;
        }

        .content {
            display: flex;
            align-items: center;
            padding: 0;
            width: 100%;

            .left,
            .right {
                width: 50%;
            }

            .left {
                min-width: 240px;

                @include breakpoint(sm) {
                    min-width: 380px;
                }
            }

            .right {
                max-width: 50%;
                padding-right: 15px;
            }

            @media screen and (max-width: 700px) {
                flex-wrap: wrap;
                align-content: center;
                // justify-content: center;
                .left,
                .right {
                    width: 100%;
                    margin-bottom: 10px;
                }
                .right {
                    max-width: 100%;
                }
            }

            @include breakpoint(xl) {
                .left {
                    min-width: 400px;
                }
            }

            @include breakpoint(xxl) {
                .left,
                .right {
                    width: 50%;
                }

                .left {
                    min-width: 600px;
                }

                .right {
                    max-width: 50%;
                }
            }

            .left {
                .box {
                    position: relative;
                    width: 124px;
                    height: 124px;
                    margin: 0 40px 0 auto;

                    @media screen and (min-width: 330px) {
                        width: 204px;
                        height: 204px;
                        margin: 0 40px 0 auto;
                    }

                    @include breakpoint(sm) {
                        width: 284px;
                        height: 284px;
                        margin: 0 80px 0 auto;
                    }

                    @include breakpoint(md) {
                        margin: 0;
                    }

                    @include breakpoint(lg) {
                        width: 350px;
                        height: 350px;
                    }

                    @include breakpoint(xl) {
                        width: 384px;
                        height: 384px;
                    }

                    @include breakpoint(xxl) {
                        width: 484px;
                        height: 484px;
                    }

                    /* Linear */
                    background: linear-gradient(
                        270deg,
                        rgba(20, 156, 255, 0) 0%,
                        #334e8d 100%
                    );
                    border-radius: 40px;
                }

                .title {
                    position: absolute;
                    width: 80%;
                    height: 168px;
                    right: -23px;
                    top: 22px;

                    font-size: 20px;
                    line-height: 24px;
                    text-align: right;

                    @include breakpoint(sm) {
                        font-size: 50px;
                        line-height: 64px;
                        right: -53px;
                        top: 42px;
                        width: 343px;
                    }
                    @include breakpoint(xl) {
                        font-size: 60px;
                        line-height: 74px;
                    }
                    @include breakpoint(xxl) {
                        font-size: 70px;
                        line-height: 84px;
                    }
                }

                img {
                    position: absolute;
                    right: -23px;
                    bottom: -28px;

                    width: calc(415px / 3.5);
                    height: calc(253px / 3.5);

                    @include breakpoint(sm) {
                        width: calc(415px / 1.6);
                        height: calc(253px / 1.6);
                        right: -53px;
                        bottom: -48px;
                    }

                    @include breakpoint(xl) {
                        width: calc(415px / 1.2);
                        height: calc(253px / 1.2);
                    }

                    @include breakpoint(xxl) {
                        width: 415px;
                        height: 253px;
                    }
                }
            }

            .right {
                .title {
                    font-size: 14px;
                    line-height: 17px;
                    letter-spacing: 0.09em;
                    text-transform: uppercase;
                    margin-bottom: 13px;
                }

                .description {
                    max-width: 645px;
                    line-height: 22px;
                    font-size: 14px;

                    p {
                        margin-bottom: 10px;
                    }
                }

                @include breakpoint(sm) {
                    .title {
                        font-size: 16px;
                        line-height: 19px;
                        margin-bottom: 23px;
                    }

                    .description {
                        font-size: 16px;
                        line-height: 24px;

                        p {
                            margin-bottom: 23px;
                        }
                    }
                }
            }

            .button-row {
                padding-top: 30px;
                a {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    transition: all 0.2s ease-in-out;

                    p {
                        text-transform: uppercase;
                        font-size: 16px;
                        line-height: 19px;
                        letter-spacing: 0.09em;
                        color: $blue;
                        padding-right: 14px;
                        transition: color 0.2s linear;
                        transition: all 0.2s ease-in-out;
                    }

                    &:hover {
                        transform: translate(2px, -2px);
                        p {
                            text-shadow: -2px 2px 3px $text-hover;
                        }

                        .icon {
                            -webkit-filter: drop-shadow(
                                -2px 2px 2px $text-hover
                            );
                            filter: drop-shadow(-2px 2px 2px $text-hover);
                        }
                    }
                }
            }
        }

        .full-width {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .card {
                position: relative;
                //width: 1110px;
                //height: 446px;
                width: 100%;
                height: 350px;
                max-height: 80vh;
                // max-height: calc(((100vw - 330px) / 100) * 49);
                background-image: url("./../../assets/img/rolls-industrial-cotton-fabric.png");
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 30px;
                background-position-x: -60px;

                @include breakpoint(sm) {
                    height: 446px;
                }

                @include breakpoint(lg) {
                    background-position-x: 0;
                }

                @include breakpoint(xxl) {
                    // height: 640px;
                    max-height: 70vh;
                }

                .card-content,
                .overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

                .overlay {
                    z-index: 0;
                    background-image: url("./../../assets/img/overlay.png");
                    background-repeat: no-repeat;
                    background-size: cover;
                    border-radius: 28px;
                    //transform: translateX(-8px);
                }

                .card-content {
                    z-index: 1;
                }

                .title {
                    font-size: 35px;
                    line-height: 49px;
                    padding: 31px 0 0 46px;
                    width: 100%;

                    &.-top {
                        position: absolute;
                        left: 26px;
                        top: 15px;
                        padding: 0;
                    }

                    @include breakpoint(lg) {
                        font-size: 50px;
                        line-height: 64px;

                        &.-top {
                            left: 46px;
                            top: 31px;
                        }
                    }

                    @include breakpoint(xxl) {
                        font-size: 70px;
                        line-height: 84px;
                    }
                }

                .bottom {
                    &.-bottom {
                        width: 90%;
                        position: absolute;
                        left: 26px;
                        bottom: 15px;
                        padding: 0;

                        .des-title,
                        .description {
                            padding-left: 0;
                        }

                        .description {
                            padding-bottom: 10px;
                        }

                        @include breakpoint(lg) {
                            left: 46px;
                            bottom: 31px;
                        }
                    }
                }

                .card-content.relative {
                    position: relative;
                }

                .card-content.flex {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                }

                .des-title {
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 19px;
                    letter-spacing: 0.09em;
                    padding: 0 0 21px 46px;
                }

                .description {
                    font-size: 16px;
                    line-height: 24px;

                    width: 472px;
                    max-width: 90%;
                    padding: 0 0 31px 46px;

                    @include breakpoint(lg) {
                        font-size: 18px;
                    }
                }

                .navigation {
                    position: absolute;
                    top: calc(100% + 30px);
                    left: 50%;
                    transform: translateX(-50%);
                    display: flex;
                    justify-content: center;

                    .circle {
                        cursor: pointer;
                        height: 8px;
                        width: 8px;
                        border-radius: 50%;
                        margin: 0 5px;
                        border: 1px solid $white;
                        background: transparent;
                        transition: background 0.2s ease-in-out;
                        opacity: 0.7;

                        &.-active {
                            background: $white;
                        }
                    }
                }
            }
        }
    }

    .certificates,
    .products {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;

        .-bg {
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            display: flex;
            align-items: center;
        }
    }

    .products {
        display: flex;
        justify-content: center;
        .-bg {
            .background-img {
                width: 100%;
                height: 257px;

                background-image: url("./../../assets/img/line.png");
                background-repeat: no-repeat;
                background-size: cover;
                transition: all 0.2s linear;

                @include breakpoint(sm) {
                    height: 337px;
                }
                @include breakpoint(md) {
                    height: 437px;
                }
                @include breakpoint(lg) {
                    height: 537px;
                }
                @include breakpoint(xl) {
                    height: 607px;
                }
                @include breakpoint(xxl) {
                    height: 808px;
                }
            }
        }

        .product-list {
            max-width: 1154px;
            width: calc(100% - 70px);
            height: 480px;
            padding: 0 60px 0 50px;
            display: flex;
            justify-content: space-between;
            position: relative;
            margin: 0 -15px;

            @include breakpoint(md) {
                padding: 0 $md-page-padding;
            }

            @include breakpoint(lg) {
                padding: 0 $lg-page-padding;
                margin: 0 -20px;
            }

            @include breakpoint(xl) {
                padding: 0 $xl-page-padding;
                margin: 0 -31px;
            }

            .product-list-inner {
                width: 100%;
                height: 100%;

                .slick-list {
                    height: 100%;
                    // display: flex;
                    // align-items: center;

                    .slick-track {
                        height: 100%;
                    }
                }

                .slick-slide {
                    padding: 30px 12px 30px 12px !important;
                }

                @media screen and (min-width: 992px) {
                    .slick-slide {
                        padding: 30px 22px 60px 22px !important;
                    }
                }
            }

            .product-card {
                width: calc(100% / 3);
                height: calc(100% / 3);
                border-radius: 20px;
                position: relative;
                // padding-bottom: 30px;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 20px;
                    margin-bottom: 10px;
                    transition: transform .2s ease-in-out;

                    @include breakpoint(md) {
                        margin-bottom: 20px;
                    }
                }

                .title {
                    font-size: 24px;
                    line-height: 29px;
                    text-shadow: 0px 4px 66px rgba(0, 0, 0, 0.12);
                    transition: color .2s ease-in-out;

                    @include breakpoint(lg) {
                        font-size: 35px;
                        line-height: 40px;
                        right: -18px;
                    }

                    @include breakpoint(xxl) {
                        font-size: 50px;
                        line-height: 55px;
                        right: -23px;
                    }
                }

                &.move-bottom {
                    transform: translateY(0);

                    @include breakpoint(sm) {
                        transform: translateY(20px);
                    }

                    @include breakpoint(lg) {
                        transform: translateY(40px);
                    }
                }

                .title-cont {
                    transition: all .2s ease-in-out;
                }

                .space-for-hover {
                    width: 100%;
                    height: 70px;

                    @include breakpoint(sm) {
                        height: 30px;
                    }
                }

                &:hover {
                    img {
                        transform: scale(1.08);
                        @include breakpoint(lg) {
                            transform: scale(1.12);
                        }
                    }

                    .title-cont {
                        transform: translateY(10px);

                        @include breakpoint(md) {
                            transform: translateY(15px);
                        }
                        @include breakpoint(lg) {
                            transform: translateY(25px);
                        }

                        .title {
                            color: $text-hover;
                        }
                    }
                }
            }

            .section-title {
                position: absolute;
                left: 15px;
                top: 30px;
                font-size: 28px;
                line-height: 42px;
                text-align: right;
                // transform: rotate(-90deg);
                // writing-mode: vertical-rl;


                @include breakpoint(md) {
                    left: 35px;
                    top: 30px;
                    font-size: 35px;
                    line-height: 49px;
                }

                @include breakpoint(lg) {
                    left: 30px;
                    top: 30px;
                    font-size: 50px;
                    line-height: 64px;
                }

                @include breakpoint(xl) {
                    font-size: 70px;
                    line-height: 84px;
                    left: 80px;
                    top: 30px;
                }
            }
        }
    }

    .certificates {
        .-bg {
            .background-img {
                width: 100%;
                height: 300px;

                background-image: url("./../../assets/img/line-2.png");
                background-repeat: no-repeat;
                background-size: cover;

                @include breakpoint(sm) {
                    height: 460px;
                }
                @include breakpoint(lg) {
                    height: 660px;
                }
            }
        }

        .certificate-list {
            max-width: 1110px;
            margin: 0 auto;
            position: relative;
            padding: 30px 50px 0 20px;
            width: calc(100% - 50px - 20px);

            @include breakpoint(md) {
                padding: 50px 50px 0 50px;
                width: calc(100% - 50px - 50px);
            }

            @include breakpoint(xl) {
                padding: 70px 145px 0 145px;
                width: calc(100% - 145px - 145px);
            }

            .home-slaider {
                width: 100%;
            }

            .section-title {
                position: absolute;
                top: -44px;
                right: 0px;
                font-size: 35px;
                line-height: 49px;
                padding-right: 60px;

                @include breakpoint(md) {
                    font-size: 50px;
                    line-height: 64px;
                    padding-right: 70px;
                    top: -50px;
                    right: 10px;
                    right: 10px;
                    right: 0px;
                }
                @include breakpoint(xl) {
                    font-size: 70px;
                    line-height: 84px;
                    padding-right: 145px;
                }
            }

            .cer {
                position: absolute;
                z-index: 99;
                top: calc(50% - 36px);
                cursor: pointer;

                &.-arrow-left {
                    left: calc(20px - 10px);
                }

                &.-arrow-right {
                    top: calc(50% + 13px);
                    right: 38px;
                }

                @include breakpoint(md) {
                    top: calc(50% - 30px);
                    &.-arrow-left {
                        left: 38px;
                    }

                    &.-arrow-right {
                        top: calc(50% + 13px);
                        right: 38px;
                    }
                }

                @include breakpoint(xl) {
                    top: calc(50% - 36px);
                    &.-arrow-left {
                        left: calc(145px - 20px);
                    }

                    &.-arrow-right {
                        top: calc(50% + 13px);
                        right: calc(145px - 20px);
                    }
                }

                @media only screen and (hover: none) and (pointer: coarse) {
                    &.-arrow-left {
                        display: none;
                    }
                    &.-arrow-right {
                        display: none;
                    }
                }
            }
        }

        .certificate-card {
            width: calc(253px * 1.3);
            // height: calc(358px * 1.3);

            &.move-bottom {
                // transform: translateY(20px);
                padding-top: 40px;
            }
            &.move-top {
                // transform: translateY(-20px);
                padding-bottom: 40px;
            }

            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }

            .title {
                font-weight: bold;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                letter-spacing: 0.09em;
                text-transform: uppercase;
                margin-top: 10px;
            }
        }
    }

    &.footer-content {
        position: relative;
        display: flex;
        align-items: center;

        @include breakpoint(sm) {
        }
        .footer {
            &.-bottom {
                // max-width: 1110px;
                position: absolute;
                bottom: 0;
                left: 0;
                // width: calc(100% - 165px - 165px);
                // padding: 177px 165px 36px 165px;
                width: calc(100% - #{$page-padding} - #{$page-padding});
                padding: 0 $page-padding 36px $page-padding;

                @include breakpoint(md) {
                    width: calc(
                        100% - #{$md-page-padding} - #{$md-page-padding}
                    );
                    padding: 0 $md-page-padding 36px $md-page-padding;
                }

                @include breakpoint(xl) {
                    width: calc(
                        100% - #{$xl-page-padding} - #{$xl-page-padding}
                    );
                    padding: 0 $xl-page-padding 36px $xl-page-padding;
                }

                .footer-content {
                    max-width: 1110px;
                    margin: 0 auto;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                }

                .footer-content > a {
                    transition: color 0.2s ease-in-out;
                    margin: 0 auto;
                    padding: 8px 0;

                    &:hover {
                        color: $text-hover;
                    }

                    @include breakpoint(sm) {
                        padding: 0;
                    }
                }

                p {
                    font-size: 16px;
                    line-height: 24px;
                    width: 100%;

                    @include breakpoint(sm) {
                        width: auto;
                    }

                    @media screen and (max-width: 575px) {
                        text-align: center;
                    }

                    a {
                        display: inline-block;
                        margin-left: 10px;
                    }
                }
            }

            &.-top {
                width: 100%;
                position: relative;
                margin-bottom: 200px;

                @include breakpoint(sm) {
                    margin-bottom: 0px;
                }

                .content {
                    // position: absolute;
                    width: calc(100% - #{$page-padding} - #{$page-padding});
                    // top: 0;
                    // left: 0;
                    padding: 0 #{$page-padding};

                    display: flex;
                    align-items: center;

                    @media screen and (max-width: 575px) {
                        flex-wrap: wrap;
                        max-height: 200px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    @include breakpoint(md) {
                        width: calc(
                            100% - #{$md-page-padding} - #{$md-page-padding}
                        );
                        padding: 0 #{$md-page-padding};
                    }
                    @include breakpoint(xl) {
                        width: calc(
                            100% - #{$xl-page-padding} - #{$xl-page-padding}
                        );
                        padding: 0 #{$xl-page-padding};
                    }

                    .left,
                    .right {
                        width: 100%;
                        @include breakpoint(sm) {
                            width: 50%;
                        }
                    }

                    .left {
                        .first {
                            font-size: 25px;
                            line-height: 35px;
                            text-align: center;

                            @include breakpoint(sm) {
                                text-align: left;
                            }
                        }

                        .second {
                            font-size: 35px;
                            line-height: 49px;
                            text-align: center;

                            @include breakpoint(sm) {
                                text-align: left;
                            }
                        }

                        @include breakpoint(md) {
                            .first {
                                font-size: 30px;
                                line-height: 40px;
                            }

                            .second {
                                font-size: 50px;
                                line-height: 64px;
                            }
                        }
                        @include breakpoint(xl) {
                            .first {
                                font-size: 50px;
                                line-height: 60px;
                            }

                            .second {
                                font-size: 70px;
                                line-height: 84px;
                            }
                        }
                    }

                    .right {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;

                        @media screen and (max-width: 575px) {
                            justify-content: center;
                        }
                        p {
                            font-size: 25px;
                            line-height: 35px;
                            padding-right: 12px;
                            cursor: pointer;

                            @include breakpoint(md) {
                                font-size: 30px;
                                line-height: 40px;
                            }
                            @include breakpoint(xl) {
                                font-size: 50px;
                                line-height: 60px;
                            }
                        }

                        .icon {
                            cursor: pointer;
                        }
                    }
                }
            }

            &.-bg {
                background-image: url("./../../assets/img/image-3.png");
                background-repeat: no-repeat;
                background-size: cover;
                height: 300px;
                width: 100%;
                background-position: center;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);

                @include breakpoint(sm) {
                    height: 308px;
                }
                @include breakpoint(md) {
                    height: 408px;
                }
                @include breakpoint(xl) {
                    height: 508px;
                }
                @include breakpoint(xxl) {
                    height: 608px;
                }
            }
        }
    }
}

.products {
    .-bg {
        .background-img {
            width: 100%;
            height: 808px;

            background-image: url("./../../assets/img/line.png");
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .product-list {
        width: 100%;
        padding: 0 165px;
        display: flex;
        justify-content: space-between;
        position: relative;

        .product-card {
            width: calc(328px * 1.4);
            height: calc(415px * 1.4);
            border-radius: 20px;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                border-radius: 20px;
            }

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                &.-blue {
                    border-radius: 20px;
                }

                &.-green {
                    border-radius: 20px;
                }
            }

            .title {
                font-size: 50px;
                line-height: 55px;
                text-shadow: 0px 4px 66px rgba(0, 0, 0, 0.12);
                // position: absolute;
                // bottom: 33px;
                // right: -23px;
                text-align: center;
            }

            &.move-bottom {
                transform: translateY(40px);
            }
        }

        .section-title {
            position: absolute;
            left: -20px;
            top: 30px;
            font-size: 70px;
            line-height: 84px;
            text-align: right;
            transform: rotate(-180deg);
            writing-mode: vertical-rl;
        }
    }
}

.pg-wrapper.pg-vertical {
    overflow: hidden !important;
}


@media screen and (max-height: 680px) {
    .section-content.content-padding .content .right .description p:last-child {
        display: none;
    }
}
