.main-menu {
    height: $menu-height;
    padding: $menu-padding;
    max-width: 1100px;
    margin: 0 auto;

    @include breakpoint(md) {
        height: $md-menu-height;
        padding: $md-menu-padding;
    }

    @include breakpoint(xl) {
        height: $xl-menu-height;
        padding: $xl-menu-padding;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
        position: relative;
        height: $logo-height;
        width: $logo-width;

        background-image: url("./../../../assets/logo.svg");
        background-repeat: no-repeat;
        background-size: contain;

        a {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
        }

        @include breakpoint(md) {
            height: $md-logo-height;
            width: $md-logo-width;
        }
    }

    .menu {
        .button-container {
            display: none;
            padding: 0 10px;

            @include breakpoint(lg) {
                display: inline-block;
            }

            @include breakpoint(xl) {
                padding: 0 20px;
            }

            .button {
                text-decoration: none;
                color: $white;
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.09em;
                text-transform: uppercase;
                position: relative;
                transition: color 0.2s ease-in-out;

                &:hover {
                    color: $text-hover;
                }

                &.active {
                    &::before {
                        content: "";
                        position: absolute;
                        top: -51px;
                        left: 0;
                        width: 100%;
                        height: 5px;
                        background: $white;
                    }
                }
            }
        }

        .action {
            display: inline-block;
            padding-right: 16px;
            position: relative;

            &.lang {
                transition: color 0.2s ease-in-out;

                .icon {
                    transition: color 0.2s ease-in-out;
                }

                &:hover {
                    .language {
                        color: $text-hover;
                        .icon {
                            filter: $svg-hover-filter;
                        }
                    }
                }
            }

            .language {
                display: flex;
                align-items: center;
                font-weight: bold;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.07em;
                text-transform: uppercase;
                cursor: pointer;

                span,
                div:first-child {
                    padding-right: 7px;
                }
            }

            .vertical-line {
                width: 1px;
                height: 25px;
                background-color: $white;
                opacity: 0.57;
                transform: translateY(5px);
            }

            .language-swich {
                position: absolute;
                top: calc(100% + 10px);
                right: 16px;
                background: rgba(0, 0, 0, 0.4);
                border-radius: 5px;
                max-height: 0;
                overflow: hidden;
                transition: max-height 0.2s linear;
                li {
                    padding: 5px 20px;

                    &:first-child {
                        padding-top: 20px;
                    }

                    &:last-child {
                        padding-bottom: 20px;
                    }

                    a {
                        text-decoration: none;
                        text-transform: uppercase;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 19px;
                        letter-spacing: 0.07em;
                        color: $white;
                        transition: color 0.2s linear;
                    }

                    a:hover {
                        color: $text-hover;
                    }
                }
            }

            &.lang:hover {
                .language-swich {
                    max-height: 150px;
                }
            }

            &.for-dextop {
                display: none;
                @include breakpoint(lg) {
                    display: inline-block;
                }
            }
            &.for-mobile {
                @include breakpoint(lg) {
                    display: none;
                }
            }
        }
    }
}

#home {
    .main-menu {
        &.fixed {
            position: fixed;
            top: 0;
            width: calc(100% - (#{$page-padding} * 2));
            z-index: 999;
            left: 50%;
            transform: translateX(-50%);

            @include breakpoint(md) {
                width: calc(100% - (#{$md-page-padding} * 2));
            }

            @include breakpoint(xl) {
                width: calc(100% - (#{$xl-page-padding} * 2));
            }
        }
    }
}
